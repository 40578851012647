import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientId, GrantType, IBasicAuth, IUser } from "api/contracts";
import api from "../api/api";
import jwtService, { UserType } from "./jwtService";

export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async () => {
    return await api.user.getUserData();
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (params, { dispatch }) => {
    const refresh_token = jwtService.getRefreshToken();
    if (!refresh_token) {
      jwtService.setSession(null);
      dispatch(resetUser());
      throw new Error();
    }

    return api.auth.revokeToken(
      {
        token: refresh_token,
        token_type_hint: "refresh_token",
      },
      {
        username: ClientId.ApiClient,
        password: process.env.REACT_APP_CLIENT_SECRET ?? "",
      }
    );
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: IBasicAuth) => {
    return api.auth.refreshToken({
      username,
      password,
      grant_type: GrantType.Password,
      client_id: ClientId.ApiClient,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    });
  }
);

export const loginWithCode = createAsyncThunk(
  "auth/loginWithCode",
  async (code: string) => {
    return api.auth.refreshToken({
      client_id: ClientId.O365,
      client_secret: process.env.REACT_APP_CLIENT_O365_SECRET,
      grant_type: GrantType.AuthorizationCode,
      code,
      redirect_uri: `${process.env.REACT_APP_REDERECT_URI}/o365`,
    });
  }
);

export const refreshToken = createAsyncThunk("auth/refreshToken", async () => {
  const refresh_token = jwtService.getRefreshToken();
  if (!refresh_token) {
    console.log("There is not refresh_token");
    throw new Error();
  }

  const userType = jwtService.getUserType();
  const client_id =
    userType === UserType.CLSUser ? ClientId.ApiClient : ClientId.O365;
  const client_secret =
    userType === UserType.CLSUser
      ? process.env.REACT_APP_CLIENT_SECRET
      : process.env.REACT_APP_CLIENT_O365_SECRET;

  return api.auth.refreshToken({
    refresh_token: refresh_token,
    grant_type: GrantType.RefreshToken,
    client_id,
    client_secret,
  });
});

const authSlice = createSlice({
  name: "auth",
  initialState: null as IUser | null,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>): IUser => {
      return action.payload as IUser;
    },
    resetUser: (): IUser | null => {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      jwtService.setSession(action.payload);
      jwtService.setUserType(UserType.CLSUser);
    });
    builder.addCase(loginWithCode.fulfilled, (state, action) => {
      jwtService.setSession(action.payload);
      jwtService.setUserType(UserType.O365User);
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      jwtService.setSession(action.payload);
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      jwtService.setSession(null);
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { resetUser, setUser } = authSlice.actions;

export default authSlice.reducer;
