import Input, { InputProps } from "../../input";
import "./primary-input.scss";

function PrimaryInput(props: InputProps) {
  return (
    <div className="primary-input">
      <Input {...props} />
    </div>
  );
}

export default PrimaryInput;
