export type ColorName =
  | "PrimaryColor"
  | "BannerColor"
  | "TrigeColor"
  | "SecondaryColor"
  | "HighlightColor"
  | "DefaultColor"
  | "WarningColor"
  | "DangerColor"
  | "DangerDarkColor"
  | "LeadColor"
  | "FontSecondaryColor"
  | "TextColor"
  | "AdditionalColor"
  | "DisabledColor"
  | "TextDisabledColor";

const colorMap: Record<ColorName, string> = {
  PrimaryColor: "#06236c",
  BannerColor: "#021d65",
  TrigeColor: "#051f65",
  SecondaryColor: "#4abf2e",
  HighlightColor: "#1592e6",
  DefaultColor: "#fff",
  WarningColor: "#f8b43a",
  DangerColor: "#ff0000",
  DangerDarkColor: "#bf2e2e",
  LeadColor: "#091f6c",
  FontSecondaryColor: "#707070",
  TextColor: "#585858",
  AdditionalColor: "#bf592e",
  DisabledColor: "#c4c4c4",
  TextDisabledColor: "#919191",
};
export default colorMap;
