import { lazy } from "react";
import paths from "../../app/route-paths";

const workloadConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.workLoad.tab,
      component: lazy(() => import("./dashboard/workload.app")),
    },
    {
      path: paths.workLoad.workloadResultRoute,
      component: lazy(() => import("./apvs-checker/apvs-checker")),
    },
  ],
};

export default workloadConfig;
