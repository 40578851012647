import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IApvsAddress,
  IApvsProperty,
  IApvsSessionResponse,
  IApvsTitleSection,
  ITitle,
} from "api/contracts";
import api from "api/api";

type initialSessionProps = {
  initialSession: IApvsSessionResponse | null;
  session: IApvsSessionResponse | null;
};

const initialSession: initialSessionProps = {
  initialSession: null,
  session: null,
};

export const getSession = createAsyncThunk(
  "apvs/getSession",
  async (sessionId: string) => {
    return await api.apvs.getSession(sessionId);
  }
);

const apvsSlice = createSlice({
  name: "APVS",
  initialState: { ...initialSession },
  reducers: {
    setCase: (state, action: PayloadAction<IApvsSessionResponse>) => {
      state.session = action.payload;
    },
    saveCase: (state, action: PayloadAction<IApvsSessionResponse>) => {
      state.initialSession = {
        ...state.session,
        ...action.payload,
      };
      state.session = {
        ...state.session,
        ...action.payload,
      };
    },
    updateCase: (state, action: PayloadAction<IApvsSessionResponse>) => {
      state.session = {
        ...state.session,
        ...action.payload,
      };
    },
    addProperty: (
      state,
      action: PayloadAction<{
        id: string;
        title: ITitle | IApvsTitleSection;
        address: IApvsAddress;
      }>
    ) => {
      if (state.session && state.session.properties) {
        const properties =
          state.session.properties?.length > 0
            ? [...state.session.properties]
            : [];
        properties.push({
          ...action.payload,
        });
        state.session.properties = properties;
      }
    },
    removeProperty: (state, action: PayloadAction<string>) => {
      if (state.session && state.session.properties) {
        const filteredProperties = state.session.properties.filter(
          (property: IApvsProperty) => property.id !== action.payload
        );
        state.session.properties = filteredProperties;
      }
    },
    clearSession: (state) => {
      state.session = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.initialSession = {
        ...action.payload,
      };
      state.session = {
        ...action.payload,
      };
    });
  },
});

export const {
  setCase,
  updateCase,
  saveCase,
  addProperty,
  removeProperty,
  clearSession,
} = apvsSlice.actions;

export default apvsSlice.reducer;
