import { lazy } from "react";
import paths from "../../app/route-paths";

const conveymapConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.conveymap.processRoute,
      component: lazy(() => import("./map/map")),
    },
    {
      path: paths.conveymap.tab,
      component: lazy(() => import("./dashboard/conveymap.app")),
    },
  ],
};

export default conveymapConfig;
