const routePaths = {
  landing: "/",
  login: "/login",
  o365: "/o365",
  contactUs: "/contact-us",
  creditsRunOut: "/credits-run-out",
  findOutMore: "/find-out-more",
  resetpassword: "/reset-password",
  newpassword: "/reset-password/:token",
  verifyemail: "/verify-email/:token",
  register: "/register",
  registerSuccess: "/register-success",
  registerFailed: "/register-failed",
  triage: "/triage",
  welcome: "/welcome",
  settings: {
    main: "/settings",
    manageTeam: "/settings/manage-team",
  },
  conveymap: {
    tab: "/conveymap/:tab?",
    processRoute: "/conveymap/process/:sessionId?",
    process: (sessionId: string) => `/conveymap/process/${sessionId}`,
    sessions: "/conveymap",
    companySessions: "/conveymap/company",
    reportSessions: "/conveymap/report",
    settings: "/conveymap/settings",
  },
  rol: {
    tab: "/rol/:tab?",
    sessions: "/rol",
    companySessions: "/rol/company",
    processRoute: `/rol/process/:caseId?`,
    process: (caseId: string) => `/rol/process/${caseId}`,
    settings: "/rol/settings",
  },
  verify: {
    tab: "/verify/:tab?",
    sessions: "/verify",
    companySessions: "/verify/company",
    batch: "/verify/batch",
    settings: "/verify/settings",
    statistic: "/verify/statistic",
    check: (caseId: string) => `/verify/checks/${caseId}`,
    checkRoute: "/verify/checks/:caseId",
    checkUnavailableRoute: "/verify/unavailable-checks/:caseId",
    checkUnavailable: (caseId: string) =>
      `/verify/unavailable-checks/${caseId}`,
  },
  propertyChecker: {
    tab: "/property-checker/:tab?",
    sessions: "/property-checker",
    companySessions: "/property-checker/company",
    settings: "/property-checker/settings",
    search: "/property-checker/search",
    resultRoute: "/property-checker/result/:sessionId?",
    result: (sessionId: string) => `/property-checker/result/${sessionId}`,
    processRoute: "/property-checker/process/:sessionId",
    process: (sessionId: string) => `/property-checker/process/${sessionId}`,
  },
  newBuild: {
    tab: "/new-build/:tab?",
    sessions: "/new-build",
    companySessions: "/new-build/company",
    settings: "/new-build/settings",
    locator: "/new-build/locator",
    support: "/new-build/support",
    processRoute: `/new-build/process/:sessionId?`,
    process: (sessionId: string) => `/new-build/process/${sessionId}`,
  },
  watchList: {
    tab: "/watchlist/:tab?",
    sessions: "/watchlist",
    companySessions: "/watchlist/company",
    processRoute: `/watchlist/process/:sessionId?`,
    process: (sessionId: string) => `/watchlist/process/${sessionId}`,
  },
  workLoad: {
    tab: "/workload/:tab?",
    cases: "/workload",
    closedCases: "/workload/closed",
    workloadResultRoute: "/workload/apvs-checker/result/:sessionId?",
    workloadResult: (sessionId: string) =>
      `/workload/apvs-checker/result/${sessionId}`,
  },
  apvs: {
    tab: "/apvs/:tab?",
    sessions: "/apvs",
    allSessions: "/apvs/company",
    processRoute: "/apvs/process/:sessionId?",
    process: (sessionId: string) => `/apvs/process/${sessionId}`,
  },
};

export default routePaths;
