import { SvgIcon } from "components/svg-icon/svg.icon";

type ButtonColor = "primary" | "default" | "danger" | "disabled";
type SimpleColor = "primary" | "default";
type AddictionColor = "lightgreen" | "darkgreen";

interface SvgColoredButtonProps extends SvgProps {
  color?: ButtonColor;
}

interface SvgColoredProps extends SvgProps {
  color?: SimpleColor;
}

interface SvgProps {
  height?: number;
  width?: number;
  className?: string;
}

class AppIcons {
  //button icons
  ChevronDown = ({
    color = "default",
  }: {
    color?: ButtonColor | AddictionColor;
  }) => <SvgIcon id={`chevron-down-${color}`} width={17.134} height={10.664} />;
  ChevronRight = ({ color = "default", ...props }: SvgColoredButtonProps) => (
    <SvgIcon
      id={`chevron-right-${color}`}
      width={10.664}
      height={17.134}
      {...props}
    />
  );
  ChevronLeft = ({ color = "default", ...props }: SvgColoredButtonProps) => (
    <SvgIcon
      id={`chevron-left-${color}`}
      width={10.664}
      height={17.134}
      {...props}
    />
  );
  BackArrow = ({ color = "default", ...props }: SvgColoredButtonProps) => (
    <SvgIcon
      id={`back-arrow-${color}`}
      width={27.346}
      height={15.294}
      {...props}
    />
  );
  Check = ({ color = "default", ...props }: SvgColoredButtonProps) => (
    <SvgIcon id={`check-${color}`} width={19.048} height={14.201} {...props} />
  );
  BinocularsWhite = ({
    color = "default",
    ...props
  }: SvgColoredButtonProps) => (
    <SvgIcon id={`binoculars-${color}`} width={40} height={40} {...props} />
  );
  StreetviewButton = ({
    color = "default",
    ...props
  }: SvgColoredButtonProps) => (
    <SvgIcon id={`streetview`} width={30} height={30} {...props} />
  );

  //triage module icons
  ModuleMap = (props: SvgProps) => (
    <SvgIcon id="module-map" width={76.381} height={61.301} {...props} />
  );
  ModuleRol = (props: SvgProps) => (
    <SvgIcon id="module-rol" width={59} height={67} {...props} />
  );
  ModuleKey = (props: SvgProps) => (
    <SvgIcon id="module-key" width={76.427} height={76.407} {...props} />
  );
  ModuleVerify = (props: SvgProps) => (
    <SvgIcon id="module-verify" width={107.3} height={93.882} {...props} />
  );
  ModuleChecker = (props: SvgProps) => (
    <SvgIcon
      id="module-prop-checker"
      width={82.761}
      height={83.115}
      {...props}
    />
  );
  ModuleWorkload = ({ color = "default", ...props }: SvgColoredButtonProps) => (
    <SvgIcon
      id={`module-workflow-${color}`}
      width={100}
      height={100}
      {...props}
    />
  );
  ModuleSettings = (props: SvgProps) => (
    <SvgIcon id="module-settings" width={100} height={100} {...props} />
  );

  //dashboard card icons
  CardMap = (props: SvgProps) => (
    <SvgIcon id="card-map" width={90.736} height={72.822} {...props} />
  );
  CardLookup = (props: SvgProps) => (
    <SvgIcon id="card-lookup" width={93.2} height={94.655} {...props} />
  );
  Binoculars = (props: SvgProps) => (
    <SvgIcon id="binoculars" width={93.2} height={94.655} {...props} />
  );

  //verify additional detail icons
  DetailsBathrooms = (props: SvgProps) => (
    <SvgIcon id="details-bathrooms" width={74.012} height={73.333} {...props} />
  );
  DetailsBedrooms = (props: SvgProps) => (
    <SvgIcon id="details-bedrooms" width={69.545} height={68.62} {...props} />
  );
  DetailsLiving = (props: SvgProps) => (
    <SvgIcon id="details-living" width={57.644} height={58.87} {...props} />
  );
  DetailsFloorSpace = (props: SvgProps) => (
    <SvgIcon
      id="details-floor-space"
      width={61.264}
      height={54.883}
      {...props}
    />
  );

  //contact icons
  ContactLinkedIn = (props: SvgProps) => (
    <SvgIcon id="linkedin" width={53.594} height={53.596} {...props} />
  );
  ContactTwitter = (props: SvgProps) => (
    <SvgIcon id="twitter" width={53.594} height={53.596} {...props} />
  );

  //tick icons
  Tick54 = (props: SvgProps) => (
    <SvgIcon id="tick54" width={54.896} height={54.896} {...props} />
  );
  Tick36 = (props: SvgProps) => (
    <SvgIcon id="tick36" width={36.797} height={36.797} {...props} />
  );
  Tick48 = (props: SvgProps) => (
    <SvgIcon id="tick48" width={48.442} height={48.442} {...props} />
  );
  Tick88 = (props: SvgProps) => (
    <SvgIcon id="tick88" width={88.6} height={88.6} {...props} />
  );

  Excel = ({ color = "default" }: { color?: ButtonColor }) => (
    <SvgIcon id={`excel-${color}`} width={18.679} height={18.43} />
  );
  Magnifier = ({ color = "primary", ...props }: SvgColoredButtonProps) => (
    <SvgIcon
      id={`magnifier-${color}`}
      width={64.277}
      height={65.439}
      {...props}
    />
  );
  ButtonMagnifier = () => <SvgIcon id="magnifier" width={30} height={30} />;
  Eye = (props: SvgProps) => (
    <SvgIcon id="eye" width={30} height={30} {...props} />
  );
  Measure = (props: SvgProps) => (
    <SvgIcon id="measure-ruler" width={30} height={30} {...props} />
  );
  Print = (props: SvgProps) => (
    <SvgIcon id="print-button" width={30} height={30} {...props} />
  );
  ButtonSave = () => <SvgIcon id="save" width={30} height={30} />;
  MapMarker = (props: SvgProps) => (
    <SvgIcon id="map-marker" width={61.058} height={86.838} {...props} />
  );
  Danger = (props: SvgProps) => (
    <SvgIcon id="danger" width={123} height={122} {...props} />
  );
  HouseFind = (props: SvgProps) => (
    <SvgIcon id="house-find" width={97.235} height={98.753} {...props} />
  );
  HousePound = (props: SvgProps) => (
    <SvgIcon id="house-pound" width={80} height={87.125} {...props} />
  );
  FileEmpty = (props: SvgProps) => (
    <SvgIcon id="file-empty" width={32} height={32} {...props} />
  );
  PropertyCheck = (props: SvgProps) => (
    <SvgIcon id="propcheck-primary" width={98.395} height={98.816} {...props} />
  );
  DocCheck = (props: SvgProps) => (
    <SvgIcon id="doccheck" width={115.846} height={117.109} {...props} />
  );
  Filter = ({ color = "default", ...props }: SvgColoredProps) => (
    <SvgIcon id={`filter-${color}`} width={18.782} height={18.782} {...props} />
  );
  Layers = (props: SvgProps) => (
    <SvgIcon id="layers" width={34.5} height={27.975} {...props} />
  );
  ClsLogo = (props: SvgProps) => (
    <SvgIcon id="cls-logo" width={62.373} height={67.741} {...props} />
  );
  Plus = (props: SvgProps) => (
    <SvgIcon id="plus" width={13.394} height={13.394} {...props} />
  );
  PlusDefault = (props: SvgProps) => (
    <SvgIcon id="plus-default" width={13.394} height={13.394} {...props} />
  );
  Pencil = (props: SvgProps) => (
    <SvgIcon id="pencil" width={18.986} height={18.962} {...props} />
  );
  Avatar = (props: SvgProps) => <SvgIcon id="avatar" {...props} />;
  PasswordReset = (props: SvgProps) => (
    <SvgIcon id="password-reset" width={115} height={115} {...props} />
  );
  Team = (props: SvgProps) => (
    <SvgIcon id="team" width={115} height={110} {...props} />
  );
  Add = (props: SvgProps) => (
    <SvgIcon id="add" width={45} height={45} {...props} />
  );
  Cancel = (props: SvgProps) => (
    <SvgIcon id="cancel" width={25.986} height={25.962} {...props} />
  );
  Cancel35 = (props: SvgProps) => (
    <SvgIcon id="cancel" width={35.896} height={35.896} {...props} />
  );
  Cancel54 = (props: SvgProps) => (
    <SvgIcon id="cancel" width={54.896} height={54.896} {...props} />
  );
  StreetView = (props: SvgProps) => (
    <SvgIcon id="streetview" width={20.986} height={20.962} {...props} />
  );
  WhiteClose = (props: SvgProps) => (
    <SvgIcon
      id="white-close-button"
      width={20.986}
      height={20.962}
      {...props}
    />
  );
  GenerateButton = (props: SvgProps) => (
    <SvgIcon id="generate-button" width={35.986} height={35.962} {...props} />
  );
  ColorWheel = (props: SvgProps) => (
    <SvgIcon id="color-wheel" width={35.986} height={35.962} {...props} />
  );
  OrderArrows = (props: SvgProps) => (
    <SvgIcon id="order-arrows" width={35.986} height={35.962} {...props} />
  );
}

export default new AppIcons();
