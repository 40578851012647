import appIcons from "components/app-icons/app-icons";
import { toast } from "react-toastify";

const notification = (
  type: "success" | "info" | "error",
  text: string,
  id?: string
) => {
  switch (type) {
    case "success":
      toast.success(text, {
        hideProgressBar: true,
        icon: appIcons.Tick36,
        toastId: id,
      });
      break;
    case "info":
      toast.info(text, {
        hideProgressBar: true,
        toastId: id,
        style: {
          fill: "red",
        },
      });
      break;
    case "error":
      toast.error(text, {
        theme: "colored",
        style: {
          backgroundColor: "#ff0000",
        },
        hideProgressBar: true,
        toastId: id,
      });
      break;
    default:
      break;
  }
};

export default notification;
