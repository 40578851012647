import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import {
  IAddress,
  IDataDictionary,
  IPad,
  IPropCheckerSession,
} from "../../api/contracts";

export const getDataDictionary = createAsyncThunk(
  "propchecker/getDataDictionary",
  async (param, { getState }) => {
    const { propCheckrSlice } = getState() as {
      propCheckrSlice: { session: IPropCheckerSession };
    };
    const { address } = propCheckrSlice.session;
    return await api.propChecker.getDataDictionary({
      addressKey: address.ADDRESS_KEY,
    });
  }
);

export const getDataListing = createAsyncThunk(
  "propchecker/getDataListing",
  async (param, { getState }) => {
    const { propCheckrSlice } = getState() as {
      propCheckrSlice: { session: IPropCheckerSession };
    };
    const { address } = propCheckrSlice.session;
    return await api.pad.getPropertyDetails(address.ADDRESS_KEY);
  }
);

export const getSession = createAsyncThunk(
  "propchecker/getSession",
  async (uid: string) => {
    return await api.propChecker.getSession({ uid });
  }
);

export const updateSession = createAsyncThunk(
  "propchecker/updateSession",
  async (param, { getState }) => {
    const { propCheckrSlice } = getState() as {
      propCheckrSlice: { session: IPropCheckerSession };
    };
    const session = propCheckrSlice.session;
    return await api.propChecker.updateSession({
      uid: session.id,
      reference: session.reference,
    });
  }
);

export const createSession = createAsyncThunk(
  "propchecker/createSession",
  async (param, { getState }) => {
    const { propCheckrSlice } = getState() as {
      propCheckrSlice: { session: IPropCheckerSession };
    };
    const session = propCheckrSlice.session;
    return await api.propChecker.createSession({
      reference: session.reference,
      //map_session_id: session.map_session_id,
      address: session.address,
    });
  }
);

const initialSession: IPropCheckerSession = {
  id: "",
  address: {} as IAddress,
  reference: "",
};

const propCheckrSlice = createSlice({
  name: "propchecker",
  initialState: {
    dataPlanning: null as IDataPlanning | null,
    dataDictionary: null as IDataDictionary | null,
    dataListing: null as IPad | null,
    session: initialSession,
    //address: null as (IAddress | null),
  },
  reducers: {
    clearSession: (state) => {
      state.dataDictionary = null;
      state.dataListing = null;
      state.session = initialSession;
    },
    setAddress: (state, action: PayloadAction<IAddress>) => {
      //state.session.address = action.payload;
      state.session.address = action.payload;
    },
    setReference: (state, action: PayloadAction<string>) => {
      state.session.reference = action.payload;
    },
    setMapSessionId: (state, action: PayloadAction<string>) => {
      state.session.map_session_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataDictionary.fulfilled, (state, action) => {
      state.dataDictionary = action.payload;
    });
    builder.addCase(getDataListing.fulfilled, (state, action) => {
      state.dataListing = action.payload;
    });
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    builder.addCase(updateSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
  },
});

export const { clearSession, setReference, setMapSessionId, setAddress } =
  propCheckrSlice.actions;
export default propCheckrSlice.reducer;
