import "./login-sidebar.scss";

function LoginSidebar() {
  return (
    <div className="login-sidebar-content">
      <div className="sidebar-content-header">Did you know</div>
      <div className="sidebar-content-text">
        We host over 1.5bn data points. All of the data found within Conveymap
        can be passed through to you via API.
      </div>
    </div>
  );
}

export default LoginSidebar;
