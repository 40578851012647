import { lazy } from "react";
import paths from "../../app/route-paths";

const verifyConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.verify.checkRoute,
      component: lazy(() => import("./wizard/verify-check/verify-check")),
    },
    {
      path: paths.verify.checkUnavailableRoute,
      component: lazy(
        () => import("./wizard/unavailable-info/unavailable-info")
      ),
    },
    {
      path: paths.verify.tab,
      component: lazy(() => import("./dashboard/verify.app")),
    },
  ],
};

export default verifyConfig;
