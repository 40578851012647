import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import { IFeature, IRolCase, IRolTitle } from "api/contracts";

export const getCase = createAsyncThunk(
  "rol/getCase",
  async (caseId: string) => {
    return await api.rol.getCase({ caseId });
  }
);

export const saveCase = createAsyncThunk(
  "rol/saveCase",
  async (param, { getState }) => {
    const { rolSlice } = getState() as { rolSlice: { case: IRolCase } };
    return await api.rol.saveCase({ ...rolSlice.case, Save: true });
  }
);

export const generate = createAsyncThunk(
  "rol/generate",
  async (param, { getState }) => {
    const { rolSlice } = getState() as { rolSlice: { case: IRolCase } };
    return await api.rol.saveCase(rolSlice.case);
  }
);

export const getFeatures = createAsyncThunk(
  "rol/getFeatures",
  async (titles: string[]) => {
    return await api.geoServer.getFeature(titles);
  }
);

export const lookupAddress = createAsyncThunk(
  "rol/lookupAddress",
  async (titleNumber: string) => {
    const addresses = await api.lookupAddress({ titleNumber });
    return { addresses, titleNumber };
  }
);

const initialFeatures: {
  feature: IFeature;
  title: string;
  highlight: boolean;
}[] = [];
const initialCase: IRolCase = {
  Reference: "",
  Titles: [],
  State: "new",
};

export enum RolFeatureType {
  Affected = "affected",
  Development = "development",
}

const rolSlice = createSlice({
  name: "rol",
  initialState: {
    case: initialCase,
    srcCase: initialCase,
    features: initialFeatures,
    rolPanelActive: false,
  },
  reducers: {
    setRolActive: (state, action: PayloadAction<boolean>) => {
      state.rolPanelActive = action.payload;
    },
    clearCase: (state) => {
      state.case = initialCase;
      state.srcCase = initialCase;
      state.features = initialFeatures;
    },
    addTitle: (state, action: PayloadAction<IRolTitle>) => {
      state.case.Titles.push(action.payload);
    },
    removeTitle: (state, action: PayloadAction<IRolTitle>) => {
      const t_index = state.case.Titles.findIndex(
        (c) => c.title_no === action.payload.title_no
      );
      if (t_index !== -1) {
        state.case.Titles.splice(t_index, 1);
      }
      const f_index = state.features.findIndex(
        (c) => c.title === action.payload.title_no
      );
      if (f_index !== -1) {
        state.features.splice(f_index, 1);
      }
    },
    updateTitle: (state, action: PayloadAction<IRolTitle>) => {
      const index = state.case.Titles.findIndex(
        (c) => c.title_no === action.payload.title_no
      );
      if (index !== -1) {
        state.case.Titles.splice(index, 1, action.payload);
      }
    },
    addFeature: (
      state,
      action: PayloadAction<{ feature: IFeature; title: string }>
    ) => {
      state.features.push({ ...action.payload, highlight: false });
    },
    highlight: (state, action: PayloadAction<IRolTitle>) => {
      const feature = state.features.find(
        (c) => c.title === action.payload.title_no
      );
      if (feature) feature.highlight = !feature.highlight;
    },
    updateCaseRef: (state, action: PayloadAction<string>) => {
      state.case.Reference = action.payload;
    },
    updateSessionId: (state, action: PayloadAction<string>) => {
      state.case.session_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCase.fulfilled, (state, action) => {
      state.case = action.payload;
      state.srcCase = action.payload;
    });
    builder.addCase(saveCase.fulfilled, (state, action) => {
      state.case = action.payload;
      state.srcCase = action.payload;
    });
    builder.addCase(generate.fulfilled, (state, action) => {
      state.case = action.payload;
      state.srcCase = action.payload;
    });
    builder.addCase(lookupAddress.fulfilled, (state, action) => {
      if (action.payload.addresses.length > 0) {
        const title = state.case.Titles.find(
          (c) => c.title_no === action.payload.titleNumber
        );
        if (title) title.reference = action.payload.addresses[0].AddressString;
      }
    });
    builder.addCase(getFeatures.fulfilled, (state, action) => {
      if (action.payload.features) {
        action.payload.features.forEach((f: IFeature) =>
          state.features.push({
            feature: f,
            title: f.properties.title_no,
            highlight: false,
          })
        );
      }
    });
  },
});
export const {
  setRolActive,
  clearCase,
  addTitle,
  removeTitle,
  updateTitle,
  addFeature,
  highlight,
  updateCaseRef,
  updateSessionId,
} = rolSlice.actions;
export default rolSlice.reducer;
