import notification from "app/helpers/notification";
import axios, {
  APIErrorResponse,
  APIResponse,
  ErrorStatus,
} from "./axios-instance";
import { AxiosResponse } from "axios";
import * as contracts from "./contracts";

export const apiPaths = {
  lookupAddress: "action/LookupAddress",
  lookupPostcode: "action/LookupPostcode",
  lookupPlace: "action/LookupPlace",
  lookupWhat3Words: "action/What3WordsLookup",
  lookupTitle: `action/LookupTitle`,
  lookupLender: "action/LookupLenders",

  auth: {
    refreshToken: "/connect/token",
    revokeToken: "/connect/revocation",
    userInfo: "/connect/userinfo",
  },

  user: {
    login: "/action/login",
    getUserData: "/action/me",
    register: "/action/Register",
    requestResetPassword: "action/RequestPasswordReset",
    resetPassword: (token: string) => `action/ResetPassword/${token}`,
    updateUserInfo: "/action/UpdateUserInfo",
    updatePassword: "/action/UpdatePazssword",
    verifyEmail: (token: string) => `action/VerifyEmail/${token}`,
    accountUsers: "action/AdminListUsers",
    createAccountUser: "action/AdminCreateUser",
    disableAccountUser: "action/AdminDisableUser",
    enableAccountUser: "action/AdminEnableUser",
  },
  pad: {
    getPropertyDetails: (addressKey: string) =>
      `/pad/property-details/${addressKey}`,
  },
  workload: {
    getAccountWorkloads: "workload/action/GetAccountWorkloads",
    markWorkloadActive: (Id: string) => `workload/action/MarkActive/${Id}`,
    markWorkloadInactive: (Id: string) => `workload/action/MarkInactive/${Id}`,
    downloadWorkloadFiles: (Id: string) =>
      `workload/action/DownloadReportProduct/${Id}`,
    uploadWorkloadFile: (Id: string) =>
      `Workload/action/UploadReportProduct/${Id}`,
  },
  conveyMap: {
    getUserSessions: "conveymap/action/GetUserSessions",
    getAccouuntSessions: "conveymap/action/GetAccountSessions",
    getSession: (sessionId: string) =>
      `conveymap/action/getsession/${sessionId}`,
    getOrderReportProducts: "conveymap/action/orderreport/getproducts",
    saveSession: "conveymap/action/AddOrUpdateSession",
    unArchiveSession: (sessionId: string) =>
      `conveymap/action/UnArchiveSession/${sessionId}`,
    archiveSession: (sessionId: string) =>
      `conveymap/action/ArchiveSession/${sessionId}`,
    getExportLink: (
      baseUrl: string,
      sessionId: string,
      authToken: string,
      scale: string,
      resolution: string,
      orientation: number,
      format: string,
      visibleLabels: boolean
    ) =>
      `${baseUrl}/conveymap/action/GetSessionPdf/${sessionId}?authkey=${authToken}&scale=${scale}&resolution=${resolution}&orientation=${orientation}&format=${format}&visibleLabels=${visibleLabels}`,
    getPrintCost: "conveymap/action/get-print-cost",
    getTravelTime: (
      type: contracts.RouteType,
      easting: number,
      northing: number,
      minutes: number
    ) =>
      `conveymap/isochrone/point?routeType=${type}&easting=${easting}&northing=${northing}&minutes=${minutes}`,
    checkIfReferenceAvailable: `conveymap/action/CheckIfReferenceAvailable`,
    downloadDdaFiles: (baseUrl: string, ddaJobId: number, authToken: string) =>
      `${baseUrl}/conveymap/download/dda/files?ddaJobId=${ddaJobId}&authKey=${authToken}`,
    downloadDdaOc1Pdf: (
      baseUrl: string,
      ddaJobId: number,
      titleNumber: string,
      authToken: string
    ) =>
      `${baseUrl}/conveymap/download/dda/oc1pdf?ddaJobId=${ddaJobId}&titleNumber=${titleNumber}&authKey=${authToken}`,
    downloadDdaResultXml: (
      baseUrl: string,
      ddaJobId: number,
      titleNumber: string,
      authToken: string
    ) =>
      `${baseUrl}/conveymap/download/dda/resultxml?ddaJobId=${ddaJobId}&titleNumber=${titleNumber}&authKey=${authToken}`,
    downloadOrderReportProduct: (
      sessionId: string,
      reportProductId?: string | number
    ) =>
      reportProductId
        ? `conveymap/download/orderreport/product/${sessionId}/${reportProductId}`
        : `conveymap/download/orderreport/product/${sessionId}`,
    dataDictionaryServices: `/data-dictionary/Services`,
    dataDictionary: (
      sessionId: string,
      addressKey: string,
      serviceName?: string
    ) => `/data-dictionary/${sessionId}/${addressKey}/${serviceName}`,
  },
  rol: {
    getUserCases: "rol/action/GetUserCases",
    getAccountCases: "rol/action/GetAccountCases",
    getCase: (caseId: string) => `/rol/action/getcase/${caseId}`,
    saveCase: "rol/action/AddCase",
    checkIfReferenceAvailable: "rol/action/CheckIfReferenceAvailable",
    checkReportState: (caseId: string) =>
      `rol/action/CheckReportState/${caseId}`,
    downloadSpreadsheet: (baseUrl: string, caseId: string, authToken: string) =>
      `${baseUrl}/rol/action/DownloadSpreadsheet/${caseId}?authKey=${authToken}`,
  },
  verify: {
    getUserCases: "action/GetUserCases",
    getAccountCases: "action/GetAccountCases",
    getJobs: "action/GetJobs",
    getStats: "action/GetStats",
    getCase: (caseId: string) => `/action/GetCase/${caseId}`,
    addCase: "action/AddCase",
    activatePolicy: "action/ActivatePolicy",
    cancelPolicy: "action/CancelPolicy",
    checkIfReferenceAvailable: "action/CheckIfReferenceAvailable",
    collect: (baseUrl: string, id: string, authToken: string) =>
      `${baseUrl}/action/Collect/${id}?authKey=${authToken}`,
    excel: "/action/GetExcelVerify",
    archiveCase: (sessionId: string) => `action/ArchiveCase/${sessionId}`,
    unArchiveCase: (sessionId: string) => `action/UnArchiveCase/${sessionId}`,
  },
  newBuild: {
    sessions: "new-build/sessions",
    userSessions: "new-build/user-sessions",
    accountSessions: "new-build/account-sessions",
    session: (uid: string) => `/new-build/sessions/${uid}`,
    getDevelopmentsByPostcode: (postcode: string) =>
      `new-build/developments/postcode/${postcode}`,
    getDevelopmentsByTown: (town: string) =>
      `new-build/developments/town/${town}`,
    getDevelopment: (id: number) => `/new-build/developments/${id}`,
    getPlot: (idPlot: number) => `/new-build/developments/plots/${idPlot}`,
    checkIfReferenceAvailable: `new-build/action/CheckIfReferenceAvailable`,
    missed: "/new-build/missed",
    getDevelopers: "/new-build/developers",
  },
  propChecker: {
    sessions: "/property-checker/sessions",
    userSessions: "/property-checker/user-sessions",
    accountSessions: "/property-checker/account-sessions",
    session: (uid: string) => `/property-checker/sessions/${uid}`,
    checkIfReferenceAvailable:
      "property-checker/action/CheckIfReferenceAvailable",
    dataDictionary: (addressKey: string) =>
      `/property-checker/data-dictionary/${addressKey}`,
    setMapSession: (uid: string, mapSessionUid: string) =>
      `/property-checker/sessions/${uid}/set-map-session/${mapSessionUid}`,
    unArchiveSession: (sessionId: string) =>
      `property-checker/unarchive-session/${sessionId}`,
    archiveSession: (sessionId: string) =>
      `property-checker/archive-session/${sessionId}`,
  },
  apvs: {
    addUpdateSession: "apvs/action/AddOrUpdateSession",
    archiveSession: (sessionId: string) =>
      `apvs/action/ArchiveSession/${sessionId}`,
    cancelSession: (sessionId: string) =>
      `apvs/action/CancelSession/${sessionId}`,
    checkIfReferenceAvailable: "apvs/action/CheckIfReferenceAvailable",
    getSessions: "apvs/action/GetUserSessions",
    getSession: (sessionId: string) => `apvs/action/GetSession/${sessionId}`,
  },
  watcher: {
    addWatcher: "watcher/action/AddWatcher",
    userWatchers: "watcher/action/GetUserWatchers",
    accountWatchers: "watcher/action/GetAccountWatchers",
    archiveWatcher: (uid: string) => `watcher/action/ArchiveWatcher/${uid}`,
  },
  geoServer: {
    getFeatureInfo: (layerName: string, bbox: string, cql?: string) =>
      `/geoserver/ows/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&QUERY_LAYERS=${layerName}&tiled=true&LAYERS=${layerName}&exceptions=application%2Fvnd.ogc.se_inimage&tilesOrigin=-118397.00155160879%2C-15982.135610342928&info_format=application/json&format=application/json&X=128&Y=128&WIDTH=256&HEIGHT=256&SRS=EPSG%3A27700&STYLES=&BBOX=${bbox}&FEATURE_COUNT=500${
        cql ? `&CQL_FILTER=${cql}` : ""
      }`,
    getModuleData: (moduleId: number) => `/geoserver/GetModuleData/${moduleId}`,
    getWms: (baseUrl: string, authToken: string) =>
      `${baseUrl}/geoserver/wms?authkey=${authToken}`,
    getFeature: (titles: string[]) => {
      const cql = titles.map((t) => `title_no='${t}'`).join(" OR ");
      return `/geoserver/HMLR/ows?service=wfs&version=2.0.0&request=GetFeature&typeNames=HMLR:HMLR_NPS&srsName=EPSG:27700&outputFormat=application%2Fjson&FEATURE_COUNT=500&CQL_FILTER=where ${cql}`;
    },
    getDragBoxFeature: "/geoserver/HMLR/ows?service=wfs",
    getLegend: (layerName: string) =>
      `/geoserver/ows?SERVICE=WMS&VERSION=1.1.1&request=GetLegendGraphic&LAYER=${layerName}&info_format=application/json&SRS=EPSG:27700&format=application/json&legend_options=countMatched:true`,
    getHistoricalIndex: (bbox: string) =>
      `/geoserver/os_historical_index/ows?service=wfs&version=2.0.0&typeNames=os_historical_index:OS_County_Series_Index&request=GetFeature&srsName=EPSG:27700&BBOX=` +
      bbox +
      `&outputFormat=application%2Fjson&FEATURE_COUNT=500`,
    getNationalGridIndex: (bbox: string) =>
      `/geoserver/os_historical_index/ows?service=wfs&version=2.0.0&typeNames=os_historical_index:OS_National_Grid_Index&request=GetFeature&srsName=EPSG:27700&BBOX=` +
      bbox +
      `&outputFormat=application%2Fjson&FEATURE_COUNT=500`,
  },
};

/* TODO: Change API calls to use try/catch with these methods - tidier error and data handling */
const handleResponse = <T>(
  response: AxiosResponse<T>,
  id?: string,
  successMessage?: string
): APIResponse<T> => {
  const result = {
    success: false,
    data: null as T | null,
    error: "",
  };
  if (!response) {
    const error = "No response received from the server.";
    notification("error", error, id);
    result.error = error;
    return result;
  }
  const { status, data } = response;
  if (status >= 200 && status < 300) {
    result.success = true;
    result.data = data;
    successMessage && notification("success", successMessage, id);
    return result;
  }
  result.error = "Unexpected status code.";
  return result;
};

const handleAxiosError = (
  error: APIErrorResponse,
  id?: string,
  defaultErrorMessage?: string
): APIResponse<null> => {
  const result = {
    success: false,
    data: null,
    error: "",
  };

  switch (error.status) {
    case ErrorStatus.Unauthorized:
      result.error = defaultErrorMessage ?? error.message;
      notification("info", result.error, id);
      break;
    case ErrorStatus.BadRequest:
      result.error = defaultErrorMessage ?? error.message;
      notification("error", result.error, id);
      break;
    case ErrorStatus.BackendError:
      result.error = defaultErrorMessage ?? error.message;
      notification("error", result.error, id);
      break;
    case ErrorStatus.UnknownError:
      result.error = defaultErrorMessage ?? error.message;
      notification("error", result.error, id);
      break;
    default:
      result.error =
        error.response?.data?.message || error.message || defaultErrorMessage;
      notification("error", result.error, id);
      break;
  }

  return result;
};

class Api implements contracts.IApi {
  baseUrl: string;
  authToken: string;

  constructor(base: string) {
    this.baseUrl = base;
    this.authToken = "";
  }

  lookupAddress = async (
    value: contracts.ILookupAddressRequest
  ): Promise<contracts.IAddress[]> => {
    const response = await axios.post<contracts.IAddress[]>(
      apiPaths.lookupAddress,
      value
    );
    return response.data;
  };

  lookupPostcode = async (
    value: contracts.ILookupAddressRequest
  ): Promise<contracts.IPostcode> => {
    const response = await axios.post<contracts.IPostcode>(
      apiPaths.lookupPostcode,
      value
    );
    return response.data;
  };

  lookupPlace = async (
    value: contracts.ILookupPlaceRequest
  ): Promise<contracts.IPlace[]> => {
    const response = await axios.post<contracts.IPlace[]>(
      apiPaths.lookupPlace,
      value
    );
    return response.data;
  };

  lookupWhat3Words = async (
    value: contracts.IWhat3WorldsRequest
  ): Promise<contracts.IWhat3WorldsResponse> => {
    const response = await axios.post<contracts.IWhat3WorldsResponse>(
      apiPaths.lookupWhat3Words,
      value
    );
    return response.data;
  };

  lookupTitles = async (value: string): Promise<contracts.ITitle> => {
    const response = await axios.post<any>(apiPaths.lookupTitle, {
      ADDRESS_KEY: value,
    });
    return response.data;
  };

  lookupLenders = async (): Promise<contracts.ILender[]> => {
    const response = await axios.get<contracts.ILender[]>(
      apiPaths.lookupLender
    );
    return response.data;
  };

  auth = {
    refreshToken: async (
      value:
        | contracts.IRefreshTokenRequest
        | contracts.IGetTokenRequest
        | contracts.IGetTokenByCodeRequest
    ): Promise<contracts.IRefreshTokenResponse> => {
      const params = new URLSearchParams();
      (Object.keys(value) as (keyof contracts.IAuthRequest)[]).forEach(
        (key) => {
          const paramValue = value[key];
          if (paramValue !== undefined) {
            params.append(key, paramValue.toString());
          }
        }
      );
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const response = await axios.post<contracts.IRefreshTokenResponse>(
        apiPaths.auth.refreshToken,
        params,
        config
      );
      return response.data;
    },
    revokeToken: async (
      value: contracts.IRevokeTokenRequest,
      auth: contracts.IBasicAuth
    ): Promise<void> => {
      const params = new URLSearchParams();
      (Object.keys(value) as (keyof contracts.IRevokeTokenRequest)[]).forEach(
        (key) => {
          const paramValue = value[key];
          if (paramValue !== undefined) {
            params.append(key, paramValue.toString());
          }
        }
      );
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: auth.username,
          password: auth.password,
        },
      };
      const response = await axios.post<void>(
        apiPaths.auth.revokeToken,
        params,
        config
      );
      return response.data;
    },
  };

  user = {
    login: async (value: contracts.ILoginRequest): Promise<contracts.IUser> => {
      const response = await axios.post<contracts.IUser>(
        apiPaths.user.login,
        value
      );
      return response.data;
    },
    getUserData: async (): Promise<contracts.IUser> => {
      const response = await axios.post<contracts.IUser>(
        apiPaths.user.getUserData
      );
      return response.data;
    },
    register: async (value: contracts.IRegisterRequest): Promise<void> => {
      const response = await axios.post<void>(apiPaths.user.register, value);
      return response.data;
    },
    requestResetPassword: async (
      value: contracts.IRequestResetPasswordRequest
    ): Promise<contracts.IRequestResetPasswordResponse> => {
      const response =
        await axios.post<contracts.IRequestResetPasswordResponse>(
          apiPaths.user.requestResetPassword,
          value
        );
      return response.data;
    },
    resetPassword: async (
      value: contracts.IResetPasswordRequest
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.post<contracts.ICodeResponse>(
        apiPaths.user.resetPassword(value.token),
        { password: value.password }
      );
      return response.data;
    },
    updateUserInfo: async (
      value: contracts.IUserInfo
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.post<contracts.ICodeResponse>(
        apiPaths.user.updateUserInfo,
        value
      );
      return response.data;
    },
    updatePassword: async (
      value: contracts.IUpdatePasswordRequest
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.post<contracts.ICodeResponse>(
        apiPaths.user.updatePassword,
        value
      );
      return response.data;
    },
    verifyEmail: async (
      value: contracts.IVerifyEmailRequest
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.post<contracts.ICodeResponse>(
        apiPaths.user.verifyEmail(value.token),
        {}
      );
      return response.data;
    },
    getAccountUsers: async (): Promise<contracts.IAccountUsersResponse> => {
      const response = await axios.get<contracts.IAccountUsersResponse>(
        apiPaths.user.accountUsers
      );
      return response.data;
    },
    createAccountUser: async (
      accountUser: contracts.IAccountUserModel
    ): Promise<contracts.IAccountUserModel> => {
      const response = await axios.post<contracts.IAccountUserModel>(
        apiPaths.user.createAccountUser,
        accountUser
      );
      return response.data;
    },
    disableAccountUser: async (
      accountUser: contracts.IAccountUserModel
    ): Promise<void> => {
      const response = await axios.post<void>(
        apiPaths.user.disableAccountUser,
        accountUser
      );
      return response.data;
    },
    enableAccountUser: async (
      accountUser: contracts.IAccountUserModel
    ): Promise<void> => {
      const response = await axios.post<void>(
        apiPaths.user.enableAccountUser,
        accountUser
      );
      return response.data;
    },
  };

  pad = {
    getPropertyDetails: async (adressKey: string): Promise<contracts.IPad> => {
      const response = await axios.get<contracts.IPad>(
        apiPaths.pad.getPropertyDetails(adressKey)
      );
      return response.data;
    },
  };

  workload = {
    getAccountWorkloads: async (
      values: contracts.IWorkloadsRequest
    ): Promise<contracts.IWorkloadsResponse | undefined> => {
      return await axios
        .post<contracts.IWorkloadsResponse>(
          apiPaths.workload.getAccountWorkloads,
          {
            ...values,
          }
        )
        .then((response) => response.data)
        .catch((error) => {
          if (error.response) {
            notification("error", error);
            return Promise.reject(error);
          } else return Promise.reject("Unknown");
        });
    },
    markWorkloadActive: async (sessionId: string) => {
      return await axios
        .get<contracts.IWorkload>(
          apiPaths.workload.markWorkloadActive(sessionId)
        )
        .then((response) => response.data)
        .catch((error) => {
          if (error.response) {
            notification("error", error, sessionId);
            return Promise.reject(error);
          } else return Promise.reject("Unknown");
        });
    },

    markWorkloadInactive: async (sessionId: string) => {
      return await axios
        .get(apiPaths.workload.markWorkloadInactive(sessionId))
        .then(() => Promise.resolve("Marked Active "))
        .catch((error) => {
          if (error.response) {
            notification("error", error, sessionId);
            return Promise.reject(error);
          } else return Promise.reject("Unknown");
        });
    },
    downloadWorkloadFiles: async (
      sessionId: string
    ): Promise<AxiosResponse<Blob> | undefined> => {
      const url = apiPaths.workload.downloadWorkloadFiles(sessionId);
      const response = await axios
        .get(url, {
          responseType: "blob",
        })
        .catch(async (error) => {
          if (error.response) {
            const { status, statusText } = error.response;
            const blob = new Blob([error.response.data], { type: "string" });
            const reason = await blob.text();
            notification(
              "error",
              status === 500 ? statusText : reason,
              sessionId
            );
            return Promise.reject(error);
          } else return Promise.reject("Unknown");
        });
      return response;
    },
    uploadWorkloadFile: async (
      sessionId: string,
      file: File
    ): Promise<AxiosResponse<any>> => {
      const formData = new FormData();
      formData.append("file", file);

      const url = apiPaths.workload.uploadWorkloadFile(sessionId);
      const response = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((error) => {
          if (error.response) {
            notification("error", error, sessionId);
            return Promise.reject(error);
          } else return Promise.reject("Unknown");
        });
      return response;
    },
  };

  conveymap = {
    getSessions: async (
      value: contracts.ISessionsRequest
    ): Promise<contracts.ISessionsResponse> => {
      const response = await axios.post<contracts.ISessionsResponse>(
        value.sessionsType === contracts.SessionsType.My
          ? apiPaths.conveyMap.getUserSessions
          : apiPaths.conveyMap.getAccouuntSessions,
        value
      );
      return response.data;
    },
    getSession: async (
      value: contracts.IArchiveRequest
    ): Promise<contracts.IConveymapSession> => {
      const response = await axios.get<contracts.IConveymapSession>(
        apiPaths.conveyMap.getSession(value.sessionId)
      );
      return response.data;
    },
    getOrderReportProducts: async (): Promise<
      contracts.IOrderReportGroups[]
    > => {
      const response = await axios.get<contracts.IOrderReportGroups[]>(
        apiPaths.conveyMap.getOrderReportProducts
      );
      return response.data;
    },
    saveSession: async (
      value: contracts.IConveymapSession
    ): Promise<contracts.IConveymapSession> => {
      const response = await axios.post<contracts.IConveymapSession>(
        apiPaths.conveyMap.saveSession,
        value
      );
      return response.data;
    },
    archiveSession: async (value: contracts.IArchiveRequest): Promise<void> => {
      await axios.get<contracts.ISessionsResponse>(
        apiPaths.conveyMap.archiveSession(value.sessionId)
      );
    },
    unArchiveSession: async (
      value: contracts.IArchiveRequest
    ): Promise<void> => {
      await axios.get<contracts.ISessionsResponse>(
        apiPaths.conveyMap.unArchiveSession(value.sessionId)
      );
    },
    getPrintCost: async (
      value: contracts.IPrintCostRequest
    ): Promise<contracts.IPrintCostResponse> => {
      const response = await axios.post<contracts.IPrintCostResponse>(
        apiPaths.conveyMap.getPrintCost,
        value
      );
      return response.data;
    },
    getTravelTime: async (
      value: contracts.IIsochroneRequest
    ): Promise<contracts.IIsochroneResult> => {
      const response = await axios.get<contracts.IIsochroneResult>(
        apiPaths.conveyMap.getTravelTime(
          value.type,
          value.easting,
          value.northing,
          value.minutes
        )
      );
      return response.data;
    },
    checkIfReferenceAvailable: async (
      value: contracts.ICheckIfReferenceAvailableRequest
    ): Promise<contracts.ICheckIfReferenceAvailableResponse> => {
      const response =
        await axios.post<contracts.ICheckIfReferenceAvailableResponse>(
          apiPaths.conveyMap.checkIfReferenceAvailable,
          value
        );
      return response.data;
    },
    downloadOrderReport: async (
      value: contracts.IDownloadOrderReportRequest
    ): Promise<AxiosResponse<Blob> | undefined> => {
      const url = apiPaths.conveyMap.downloadOrderReportProduct(
        value.sessionId,
        value.reportProductId
      );
      const response = await axios
        .get(url, {
          responseType: "blob",
        })
        .catch(async (error) => {
          if (error.response) {
            const { status, statusText } = error.response;
            const blob = new Blob([error.response.data], { type: "string" });
            const reason = await blob.text();
            notification(
              "error",
              status === 500 ? statusText : reason,
              value.sessionId
            );
            return Promise.reject(error);
          } else return Promise.reject("Unknown");
        });

      return response;
    },
    getDataDictionaryServices: async (): Promise<string[]> => {
      const response = await axios.get<string[]>(
        apiPaths.conveyMap.dataDictionaryServices
      );
      return response.data;
    },
    getDataDictionary: async (
      value: contracts.IDataDictionaryRequest
    ): Promise<contracts.IDataDictionary> => {
      const response = await axios.get<contracts.IDataDictionary>(
        apiPaths.conveyMap.dataDictionary(
          value.sessionId as string,
          value.addressKey,
          value.serviceName
        )
      );
      return response.data;
    },
  };
  rol = {
    getCases: async (
      value: contracts.ICasesRequest
    ): Promise<contracts.IRolCasesResponse> => {
      const response = await axios.post<contracts.IRolCasesResponse>(
        value.sessionsType === contracts.SessionsType.My
          ? apiPaths.rol.getUserCases
          : apiPaths.rol.getAccountCases,
        value
      );
      return response.data;
    },
    getCase: async (
      value: contracts.ICaseRequest
    ): Promise<contracts.IRolCase> => {
      const response = await axios.get<contracts.IRolCase>(
        apiPaths.rol.getCase(value.caseId)
      );
      return response.data;
    },
    saveCase: async (
      value: contracts.IRolCase
    ): Promise<contracts.IRolCase> => {
      const response = await axios.post<contracts.IRolCase>(
        apiPaths.rol.saveCase,
        value
      );
      return response.data;
    },
    checkIfReferenceAvailable: async (
      value: contracts.ICheckIfReferenceAvailableRequest
    ): Promise<contracts.ICheckIfReferenceAvailableResponse> => {
      const response =
        await axios.post<contracts.ICheckIfReferenceAvailableResponse>(
          apiPaths.rol.checkIfReferenceAvailable,
          value
        );
      return response.data;
    },
    checkReportState: async (
      value: contracts.ICaseRequest
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.get<contracts.ICodeResponse>(
        apiPaths.rol.checkReportState(value.caseId)
      );
      return response.data;
    },
  };
  verify = {
    getCases: async (
      value: contracts.ICasesRequest
    ): Promise<contracts.IVerifyCasesResponse> => {
      const response = await axios.post<contracts.IVerifyCasesResponse>(
        value.sessionsType === contracts.SessionsType.My
          ? apiPaths.verify.getUserCases
          : apiPaths.verify.getAccountCases,
        value
      );
      return response.data;
    },
    getCase: async (
      value: contracts.ICaseRequest
    ): Promise<contracts.ICase> => {
      const response = await axios.get<contracts.ICase>(
        apiPaths.verify.getCase(value.caseId)
      );
      return response.data;
    },
    addCase: async (value: contracts.ICase): Promise<contracts.ICase> => {
      const response = await axios.post<contracts.ICase>(
        apiPaths.verify.addCase,
        value
      );
      return response.data;
    },
    getJobs: async (): Promise<contracts.IJobsResponse[]> => {
      const response = await axios.post<contracts.IJobsResponse[]>(
        apiPaths.verify.getJobs
      );
      return response.data;
    },
    getStats: async (): Promise<contracts.IStatsResponse> => {
      const response = await axios.post<contracts.IStatsResponse>(
        apiPaths.verify.getStats
      );
      return response.data;
    },
    activatePolicy: async (
      value: contracts.ICaseRequest
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.post<contracts.ICodeResponse>(
        apiPaths.verify.activatePolicy,
        value
      );
      return response.data;
    },
    cancelPolicy: async (
      value: contracts.ICaseRequest
    ): Promise<contracts.ICodeResponse> => {
      const response = await axios.post<contracts.ICodeResponse>(
        apiPaths.verify.cancelPolicy,
        value
      );
      return response.data;
    },
    checkIfReferenceAvailable: async (
      value: contracts.ICheckIfReferenceAvailableRequest
    ): Promise<contracts.ICheckIfReferenceAvailableResponse> => {
      const response =
        await axios.post<contracts.ICheckIfReferenceAvailableResponse>(
          apiPaths.verify.checkIfReferenceAvailable,
          value
        );
      return response.data;
    },
    getExcele: async (
      value: contracts.IVerifyCaseExcelRequest
    ): Promise<any> => {
      const response = await axios.post<any>(apiPaths.verify.excel, value, {
        responseType: "blob",
      });
      return response.data;
    },
    archiveCase: async (value: contracts.IArchiveRequest): Promise<void> => {
      await axios.get<contracts.ISessionsResponse>(
        apiPaths.verify.archiveCase(value.sessionId)
      );
    },
    unArchiveCase: async (value: contracts.IArchiveRequest): Promise<void> => {
      await axios.get<contracts.ISessionsResponse>(
        apiPaths.verify.unArchiveCase(value.sessionId)
      );
    },
  };
  newBuild = {
    getSessions: async (
      value: contracts.IExtReferenceRequest
    ): Promise<contracts.INewBuildSession[]> => {
      const response = await axios.get<contracts.INewBuildSession[]>(
        value.sessionsType === contracts.SessionsType.My
          ? apiPaths.newBuild.userSessions
          : apiPaths.newBuild.accountSessions,
        { params: value }
      );
      return response.data;
    },
    getSession: async (
      value: contracts.ISessionRequest
    ): Promise<contracts.INewBuildSession> => {
      const response = await axios.get<contracts.INewBuildSession>(
        apiPaths.newBuild.session(value.uid)
      );
      return response.data;
    },
    createSession: async (
      value: contracts.INewBuildCreateRequest
    ): Promise<contracts.INewBuildSession> => {
      const response = await axios.post<contracts.INewBuildSession>(
        apiPaths.newBuild.sessions,
        value
      );
      return response.data;
    },
    updateSession: async (
      value: contracts.INewBuildUpdateRequest
    ): Promise<contracts.INewBuildSession> => {
      const response = await axios.put<contracts.INewBuildSession>(
        apiPaths.newBuild.session(value.id),
        value.value
      );
      return response.data;
    },
    getDevelopmentsByPostcode: async (
      value: contracts.IDevelopmentsByPostcodeRequest
    ): Promise<contracts.IDevelopment[]> => {
      const response = await axios.get<contracts.IDevelopment[]>(
        apiPaths.newBuild.getDevelopmentsByPostcode(value.postcode)
      );
      return response.data;
    },
    getDevelopmentsByTown: async (
      value: contracts.IDevelopmentsByTownRequest
    ): Promise<contracts.IDevelopment[]> => {
      const response = await axios.get<contracts.IDevelopment[]>(
        apiPaths.newBuild.getDevelopmentsByTown(value.town)
      );
      return response.data;
    },
    getDevelopment: async (id: number): Promise<contracts.IDevelopment_NEW> => {
      const response = await axios.get<contracts.IDevelopment_NEW>(
        apiPaths.newBuild.getDevelopment(id)
      );
      return response.data;
    },
    getPlot: async (idPlot: number): Promise<contracts.IPlotFull> => {
      const response = await axios.get<contracts.IPlotFull>(
        apiPaths.newBuild.getPlot(idPlot)
      );
      return response.data;
    },
    checkIfReferenceAvailable: async (
      value: contracts.ICheckIfReferenceAvailableRequest
    ): Promise<contracts.ICheckIfReferenceAvailableResponse> => {
      const response =
        await axios.post<contracts.ICheckIfReferenceAvailableResponse>(
          apiPaths.newBuild.checkIfReferenceAvailable,
          value
        );
      return response.data;
    },
    missed: async (value: FormData): Promise<void> => {
      const response = await axios.post<void>(apiPaths.newBuild.missed, value);
      return response.data;
    },
    getDevelopers: async (): Promise<contracts.IDeveloper[]> => {
      const response = await axios.get<contracts.IDeveloper[]>(
        apiPaths.newBuild.getDevelopers
      );
      return response.data;
    },
  };
  propChecker = {
    getSessions: async (
      value: contracts.ISessionsRequest
    ): Promise<contracts.IPropCheckerSession[]> => {
      const response = await axios.post<contracts.IPropCheckerSession[]>(
        value.sessionsType === contracts.SessionsType.My
          ? apiPaths.propChecker.userSessions
          : apiPaths.propChecker.accountSessions,
        value
      );
      return response.data;
    },
    getSession: async (
      value: contracts.ISessionRequest
    ): Promise<contracts.IPropCheckerSession> => {
      const response = await axios.get<contracts.IPropCheckerSession>(
        apiPaths.propChecker.session(value.uid)
      );
      return response.data;
    },
    createSession: async (
      value: contracts.IPropCheckerCreateRequest
    ): Promise<contracts.IPropCheckerSession> => {
      const response = await axios.post<contracts.IPropCheckerSession>(
        apiPaths.propChecker.sessions,
        value
      );
      return response.data;
    },
    updateSession: async (
      value: contracts.IPropCheckerUpdateRequest
    ): Promise<contracts.IPropCheckerSession> => {
      const response = await axios.put<contracts.IPropCheckerSession>(
        apiPaths.propChecker.session(value.uid),
        { reference: value.reference }
      );
      return response.data;
    },
    checkIfReferenceAvailable: async (
      value: contracts.ICheckIfReferenceAvailableRequest
    ): Promise<contracts.ICheckIfReferenceAvailableResponse> => {
      const response =
        await axios.post<contracts.ICheckIfReferenceAvailableResponse>(
          apiPaths.propChecker.checkIfReferenceAvailable,
          value
        );
      return response.data;
    },
    getDataDictionary: async (
      value: contracts.IDataDictionaryRequest
    ): Promise<contracts.IDataDictionary> => {
      const response = await axios.get<contracts.IDataDictionary>(
        apiPaths.propChecker.dataDictionary(value.addressKey)
      );
      return response.data;
    },
    setMapSession: async (
      value: contracts.ISetMapSessionRequest
    ): Promise<void> => {
      const response = await axios.put<void>(
        apiPaths.propChecker.setMapSession(value.uid, value.mapSessionUid)
      );
      return response.data;
    },
    archiveSession: async (value: contracts.IArchiveRequest): Promise<void> => {
      await axios.get<contracts.ISessionsResponse>(
        apiPaths.propChecker.archiveSession(value.sessionId)
      );
    },
    unArchiveSession: async (
      value: contracts.IArchiveRequest
    ): Promise<void> => {
      await axios.get<contracts.ISessionsResponse>(
        apiPaths.propChecker.unArchiveSession(value.sessionId)
      );
    },
  };
  apvs = {
    addUpdateSession: async (
      values: contracts.IApvsSessionRequest
    ): Promise<APIResponse<contracts.IApvsSessionResponse | null>> => {
      try {
        const response = await axios.post<contracts.IApvsSessionResponse>(
          apiPaths.apvs.addUpdateSession,
          values
        );
        return handleResponse(response, values.id);
      } catch (error) {
        return handleAxiosError(error as APIErrorResponse, values.id);
      }
    },
    archiveSession: async <T>(
      value: string
    ): Promise<APIResponse<T | null>> => {
      try {
        const response = await axios.get(apiPaths.apvs.archiveSession(value));
        return handleResponse(
          response,
          value,
          "Successfully archived the session."
        );
      } catch (error) {
        return handleAxiosError(
          error as APIErrorResponse,
          value,
          "Unable to archive the session. Please try again."
        );
      }
    },
    cancelSession: async <T>(
      id: string,
      reason: string
    ): Promise<APIResponse<T | null>> => {
      try {
        const response = await axios.post(apiPaths.apvs.cancelSession(id), {
          cancellationReason: reason,
        });
        return handleResponse(response, id);
      } catch (error) {
        return handleAxiosError(
          error as APIErrorResponse,
          id,
          "Unable to cancel the session. Please try again."
        );
      }
    },
    checkIfReferenceAvailable: async (
      value: contracts.ICheckIfReferenceAvailableRequest
    ): Promise<contracts.ICheckIfReferenceAvailableResponse> => {
      const response =
        await axios.post<contracts.ICheckIfReferenceAvailableResponse>(
          apiPaths.apvs.checkIfReferenceAvailable,
          value
        );
      return response.data;
    },
    getSessions: async (
      searchRequest: contracts.IApvsSearchRequest
    ): Promise<APIResponse<contracts.IApvsSessionResponse[] | null>> => {
      try {
        const response = await axios.post<contracts.IApvsSessionResponse[]>(
          apiPaths.apvs.getSessions,
          searchRequest
        );
        return handleResponse(response, searchRequest.search);
      } catch (error) {
        return handleAxiosError(
          error as APIErrorResponse,
          searchRequest.search,
          "Unable to fetch the sessions. Please try again."
        );
      }
    },
    getSession: async (
      value: string
    ): Promise<contracts.IApvsSessionResponse> => {
      const response = await axios.get<contracts.IApvsSessionResponse>(
        apiPaths.apvs.getSession(value)
      );
      return response.data;
    },
  };
  watcher = {
    addWatcher: async (
      value: contracts.IWatcher
    ): Promise<contracts.IWatcher> => {
      const response = await axios.post<contracts.IWatcher>(
        apiPaths.watcher.addWatcher,
        value
      );
      return response.data;
    },
    getWatchers: async (
      value: contracts.ISessionsRequest
    ): Promise<contracts.IWatcherResponse> => {
      const response = await axios.post<contracts.IWatcherResponse>(
        value.sessionsType === contracts.SessionsType.My
          ? apiPaths.watcher.userWatchers
          : apiPaths.watcher.accountWatchers,
        value
      );
      return response.data;
    },
    archiveWatcher: async (uid: string): Promise<void> => {
      const response = await axios.get<void>(
        apiPaths.watcher.archiveWatcher(uid)
      );
      return response.data;
    },
  };

  geoServer = {
    getFeatureInfo: async (
      layerName: string,
      bbox: string,
      cql?: string
    ): Promise<contracts.IFeatureInfo> => {
      const response = await axios.get<contracts.IFeatureInfo>(
        apiPaths.geoServer.getFeatureInfo(layerName, bbox, cql)
      );
      return response.data;
    },
    getModuleData: async (
      value: contracts.IModuleDataRequest
    ): Promise<contracts.IModuleDataResponse> => {
      const response = await axios.get<contracts.IModuleDataResponse>(
        apiPaths.geoServer.getModuleData(value.moduleId)
      );
      return response.data;
    },
    getFeature: async (title: string[]): Promise<any> => {
      const response = await axios.get<any>(
        apiPaths.geoServer.getFeature(title)
      );
      return response.data;
    },

    getDragBoxFeature: async (bbox: string): Promise<any> => {
      /* TODO: Get this working, either by the XML upload or cql filter */
      const [minX, minY, maxX, maxY] = bbox.split(",");
      const xml = `<?xml version="1.0" encoding="UTF-8"?>
      <wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" version="1.1.0" outputFormat="application/json" resultType="results">
        <wfs:Query typeName="HMLR_NPS" srsName="urn:x-ogc:def:crs:EPSG:27700">
          <ogc:Filter>
            <ogc:Intersects>
              <ogc:PropertyName>geom</ogc:PropertyName>
              <gml:Envelope srsName="urn:x-ogc:def:crs:EPSG:27700">
                <gml:lowerCorner>${minX} ${minY}</gml:lowerCorner>
                <gml:upperCorner>${maxX} ${maxY}</gml:upperCorner>
              </gml:Envelope>
            </ogc:Intersects>
          </ogc:Filter>
        </wfs:Query>
      </wfs:GetFeature>`;

      const response = await axios.post<any>(
        `/geoserver/HMLR/ows?service=wfs&version=2.0.0&request=GetFeature&typeNames=HMLR:HMLR_NPS&srsName=urn:x-ogc:def:crs:EPSG:27700&outputFormat=application%2Fjson&FEATURE_COUNT=500&CQL_FILTER=Intersects(geom, ENVELOPE(${minX}, ${maxX}, ${maxY}, ${minY}))`
      );

      // const response = await axios.post<any>(
      //   apiPaths.geoServer.getDragBoxFeature,
      //   xml,
      //   {
      //     headers: {
      //       "Content-Type": "application/xml",
      //     },
      //   }
      // );
      return response.data;
    },
    getLegend: async (layerName: string): Promise<any> => {
      const response = await axios.get<any>(
        apiPaths.geoServer.getLegend(layerName)
      );
      return response.data;
    },
    getHistoricalIndex: async (bbox: string): Promise<any> => {
      const response = await axios.get<any>(
        apiPaths.geoServer.getHistoricalIndex(bbox)
      );
      return response.data;
    },
    getNationalGridIndex: async (bbox: string): Promise<any> => {
      const response = await axios.get<any>(
        apiPaths.geoServer.getNationalGridIndex(bbox)
      );
      return response.data;
    },
  };
}

const instance = new Api(
  process.env.REACT_APP_BASE_URL || ""
  //window.localStorage.getItem('jwt_access_token') || ""
);

export default instance;
