import { Suspense } from "react";
import Loading from "components/loader/loader";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFoundScreen from "./not-found-screen";
import withAuth from "auth/withAuth";
import paths from "./route-paths";
import Login from "./login/login/Login";

import InterceptorWrapper from "api/interceptor-wrapper";

interface IRoutingProps {
  routeConfig: RouteConfig[];
}

const Routing = (props: IRoutingProps) => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <InterceptorWrapper>
          <Switch>
            <Route exact path={paths.login} component={Login} />
            {props.routeConfig.map((route, index) => (
              <Route
                exact
                key={index}
                path={route.path}
                component={withAuth(route.component, route.authRequire)}
              />
            ))}
            <Route path="/" component={NotFoundScreen} />
          </Switch>
        </InterceptorWrapper>
      </Suspense>
    </Router>
  );
};

export default Routing;
