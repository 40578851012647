import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import {
  IDevelopment,
  IDevelopment_NEW,
  INewBuildSession,
} from "../../api/contracts";

export const getDevelopments = createAsyncThunk(
  "newbuild/getDevelopments",
  async (value: { postcode: string }) => {
    return await api.newBuild.getDevelopmentsByPostcode(value);
  }
);

export const getSession = createAsyncThunk(
  "newbuild/getSession",
  async (uid: string) => {
    return await api.newBuild.getSession({ uid });
  }
);

export const updateSession = createAsyncThunk(
  "newbuild/updateSession",
  async (param, { getState }) => {
    const { newbuildSlice } = getState() as {
      newbuildSlice: { session: INewBuildSession };
    };
    const session = newbuildSlice.session;
    return await api.newBuild.updateSession({
      id: session.id,
      value: {
        development_id: session.development?.id || 0,
        phase: session.phase,
        plot: session.plot,
        reference: session.reference,
        map_session_id: session.session_id,
      },
    });
  }
);

export const createSession = createAsyncThunk(
  "newbuild/createSession",
  async (param, { getState }) => {
    const { newbuildSlice } = getState() as {
      newbuildSlice: { session: INewBuildSession };
    };
    const session = newbuildSlice.session;
    return await api.newBuild.createSession({
      development_id: session.development?.id || 0,
      phase: session.phase,
      plot: session.plot,
      reference: session.reference,
      map_session_id: session.session_id,
    });
  }
);

const initialDevelopments: IDevelopment[] = [];
const initialSession: INewBuildSession = {
  id: "",
  reference: "",
  session_id: "",
};

const newbuildSlice = createSlice({
  name: "newbuild",
  initialState: {
    developments: initialDevelopments,
    session: initialSession,
  },
  reducers: {
    clearSession: (state) => {
      state.session = initialSession;
      state.developments = initialDevelopments;
    },
    setPlot: (state, action: PayloadAction<number>) => {
      state.session.plot = action.payload;
    },
    setPhase: (state, action: PayloadAction<number>) => {
      state.session.phase = action.payload;
    },
    setDevelopment: (state, action: PayloadAction<IDevelopment_NEW>) => {
      state.session.development = action.payload;
    },
    setReference: (state, action: PayloadAction<string>) => {
      state.session.reference = action.payload;
    },
    setMapSessionId: (state, action: PayloadAction<string>) => {
      state.session.session_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDevelopments.fulfilled, (state, action) => {
      state.developments = action.payload;
    });
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    builder.addCase(updateSession.fulfilled, (state, action) => {
      //state.session = action.payload;
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      state.session.id = action.payload.id;
    });
  },
});

export const {
  clearSession,
  setPlot,
  setPhase,
  setReference,
  setDevelopment,
  setMapSessionId,
} = newbuildSlice.actions;
export default newbuildSlice.reducer;
