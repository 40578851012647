import { lazy } from "react";
import paths from "../../app/route-paths";

const settingsConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.settings.main,
      component: lazy(() => import("./settings.app")),
    },
    {
      path: paths.settings.manageTeam,
      component: lazy(() => import("./manage-team/manage-team")),
    },
  ],
};

export default settingsConfig;
