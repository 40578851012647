import { lazy } from "react";
import paths from "../../app/route-paths";

const watchListConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.watchList.tab,
      component: lazy(() => import("./dashboard/watchlist.app")),
    },
  ],
};

export default watchListConfig;
