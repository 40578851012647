import React from "react";

interface Props {
  id: string;
  fill?: string;
  height?: number;
  width?: number;
  className?: string;
  style?: React.CSSProperties;
  tooltip?: string;
}

export const SvgIcon: React.FC<Props> = ({
  id,
  fill = "#F0F0F0",
  height = 24,
  width = 24,
  className,
  style,
  tooltip,
}) => {
  return (
    <svg
      fill={fill}
      className={className}
      style={style}
      width={width}
      height={height}
    >
      <use xlinkHref={`/images/svg/sprite.symbol.svg#${id}`} />
      {tooltip && <title>{tooltip}</title>}
    </svg>
  );
};
