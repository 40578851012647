import { HTMLAttributes } from "react";
import "./menu.scss";

interface IMenuProps extends HTMLAttributes<HTMLDivElement> {
  items: IMenuItem[];
}

interface IMenuItem {
  title: string;
  path: string;
}

function MenuComponent({ items, ...props }: IMenuProps) {
  return (
    <div {...props} className="menu">
      {items.map((item, index) => (
        <a key={index} href={item.path}>
          {item.title}
        </a>
      ))}
    </div>
  );
}

export default MenuComponent;
