import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const drawSlice = createSlice({
  name: "mapSlice",
  initialState: {
    streetViewModeCoordinates: [] as number[],
  },
  reducers: {
    setStreetViewModeCoordinates: (state, action: PayloadAction<number[]>) => {
      state.streetViewModeCoordinates = action.payload;
    },
  },
});

export const { setStreetViewModeCoordinates } = drawSlice.actions;
export default drawSlice.reducer;
