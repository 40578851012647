import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DrawItems } from "components/map/map-controls/map-tools/map-tools-types";

interface IDrawState {
  lastDrawFeatureId: string | number | null;
  selectFeatureId: string | number | null;
  displayFeatureMeasure: boolean;
  displayFeatureTitle: boolean;
  drawingState: boolean;
  drawTool: DrawItems | null;
}

const initialDrawState: IDrawState = {
  lastDrawFeatureId: null,
  selectFeatureId: null,
  displayFeatureMeasure: true,
  displayFeatureTitle: true,
  drawingState: false,
  drawTool: null,
};

const drawSlice = createSlice({
  name: "drawSlice",
  initialState: initialDrawState,
  reducers: {
    setDisplayFeatureTitle: (state, action: PayloadAction<boolean>) => {
      state.displayFeatureTitle = action.payload;
    },
    setLastDrawFeatureId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.lastDrawFeatureId = action.payload;
    },
    setSelectFeatureId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.selectFeatureId = action.payload;
    },
    setDisplayFeatureMeasure: (state, action: PayloadAction<boolean>) => {
      state.displayFeatureMeasure = action.payload;
    },
    setDrawingState: (state, action: PayloadAction<boolean>) => {
      state.drawingState = action.payload;
    },
    setDrawTool: (state, action: PayloadAction<DrawItems | null>) => {
      state.drawTool = action.payload;
    },
  },
});

export const {
  setLastDrawFeatureId,
  setSelectFeatureId,
  setDisplayFeatureMeasure,
  setDisplayFeatureTitle,
  setDrawingState,
  setDrawTool,
} = drawSlice.actions;
export default drawSlice.reducer;
