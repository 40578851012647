import { FooterComponent } from "components/layout/footer/footer";
import routePaths from "app/route-paths";
import ClsLogo from "components/cls-logo/cls-logo";
import MenuComponent from "components/menu/menu";
import { ReactNode } from "react";
import "./login-layout.scss";
import LoginSidebar from "./login-sidebar/login-sidebar";

interface LoginLayoutProps {
  content: ReactNode;
  sidebar?: ReactNode;
}

function LoginLayout(props: LoginLayoutProps) {
  const { content, sidebar = <LoginSidebar /> } = props;
  const menuItems = [
    {
      title: "Login",
      path: routePaths.login,
    },
    {
      title: "Create an account",
      path: routePaths.register,
    },
    {
      title: "Contact Us",
      path: routePaths.contactUs,
    },
  ];

  return (
    <div className="login__container">
      <div className="login__content">
        <div className="login__form-container">
          <div className="login__form-auto">
            <div className="login__form-header">
              <ClsLogo />
            </div>
            {content}
          </div>
        </div>
        <FooterComponent style={{ position: "relative", flex: "1 1 1000px" }} />
      </div>
      <div className="login__sidebar">
        <div className="login__sidebar-background">
          <div className="login__sidebar-vh">
            <div className="login__sidebar-menu">
              <MenuComponent
                style={{ color: "white", height: 100 }}
                items={menuItems}
              />
            </div>
            <div className="login__sidebar-content">
              {sidebar}
              {/* <div className="login__sidebar-content-header">Did you know</div>
                            <div className="login__sidebar-content-text">We host over 1.5bn data points. All of the data found within Conveymap can be passed through to you via API.</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;
