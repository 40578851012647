import { APIResponse } from "./axios-instance";

export interface IApi {
  baseUrl: string;
  authToken: string;

  lookupAddress: (request: ILookupAddressRequest) => Promise<IAddress[]>;
  lookupPostcode: (request: ILookupAddressRequest) => Promise<IPostcode>;
  lookupPlace: (request: ILookupPlaceRequest) => Promise<IPlace[]>;
  lookupWhat3Words: (
    request: IWhat3WorldsRequest
  ) => Promise<IWhat3WorldsResponse>;
  lookupTitles: (request: string) => Promise<ITitle>;
  lookupLenders: () => Promise<ILender[]>;

  user: IUserApi;
  workload: IWorkloadAPI;
  conveymap: IConveymapApi;
  rol: IRolApi;
  verify: IVerifyApi;
  newBuild: INewBuildApi;
  propChecker: IPropCheckerApi;
  geoServer: IGeoServerApi;
  pad: IPadApi;
  auth: IAuthApi;
  watcher: IWatcherApi;
  apvs: IAPVSApi;
}
export interface IPadApi {
  getPropertyDetails: (adressKey: string) => Promise<IPad>;
}

export interface IAuthApi {
  refreshToken: (
    value: IRefreshTokenRequest | IGetTokenRequest | IGetTokenByCodeRequest
  ) => Promise<IRefreshTokenResponse>;
  revokeToken: (value: IRevokeTokenRequest, auth: IBasicAuth) => Promise<void>;
}

export interface IUserApi {
  login: (request: ILoginRequest) => Promise<IUser>;
  register: (request: IRegisterRequest) => Promise<void>;
  requestResetPassword: (
    request: IRequestResetPasswordRequest
  ) => Promise<IRequestResetPasswordResponse>;
  resetPassword: (request: IResetPasswordRequest) => Promise<ICodeResponse>;
  updateUserInfo: (request: IUserInfo) => Promise<ICodeResponse>;
  updatePassword: (request: IUpdatePasswordRequest) => Promise<ICodeResponse>;
  verifyEmail: (request: IVerifyEmailRequest) => Promise<ICodeResponse>;
  getAccountUsers: () => Promise<IAccountUsersResponse>;
  createAccountUser: (request: IAccountUserModel) => Promise<IAccountUserModel>;
  disableAccountUser: (request: IAccountUserModel) => Promise<void>;
  enableAccountUser: (request: IAccountUserModel) => Promise<void>;
}

export interface IWorkloadAPI {
  getAccountWorkloads: (
    request: IWorkloadsRequest
  ) => Promise<IWorkloadsResponse | undefined>;
  markWorkloadActive: (sessionId: string) => Promise<IWorkload>;
  markWorkloadInactive: (sessionId: string) => Promise<string>;
  downloadWorkloadFiles: (sessionId: string) => Promise<any>;
  uploadWorkloadFile: (sessionId: string, file: File) => Promise<any>;
}

export interface IConveymapApi {
  getSessions: (request: ISessionsRequest) => Promise<ISessionsResponse>;
  getSession: (request: IArchiveRequest) => Promise<IConveymapSession>;
  archiveSession: (request: IArchiveRequest) => Promise<void>;
  unArchiveSession: (request: IArchiveRequest) => Promise<void>;
  getPrintCost: (request: IPrintCostRequest) => Promise<IPrintCostResponse>;
  getTravelTime: (request: IIsochroneRequest) => Promise<IIsochroneResult>;
  checkIfReferenceAvailable: (
    value: IReferenceRequest
  ) => Promise<ICheckIfReferenceAvailableResponse>;
  downloadOrderReport: (request: IDownloadOrderReportRequest) => Promise<any>;
  getDataDictionaryServices: () => Promise<string[]>;
  getDataDictionary: (request: IDataDictionaryRequest) => Promise<any>;
}

export interface IGeoServerApi {
  getFeatureInfo: (
    layerName: string,
    bbox: string,
    cql?: string
  ) => Promise<IFeatureInfo>;
  getModuleData: (request: IModuleDataRequest) => Promise<IModuleDataResponse>;
  getFeature: (title: string[]) => Promise<any>;
  getDragBoxFeature: (bbox: string) => Promise<any>;
  getLegend: (layerName: string) => Promise<any>;
  getHistoricalIndex: (bbox: string) => Promise<any>;
  getNationalGridIndex: (bbox: string) => Promise<any>;
}

export interface IRolApi {
  getCases: (request: ICasesRequest) => Promise<IRolCasesResponse>;
  getCase: (value: ICaseRequest) => Promise<IRolCase>;
  saveCase: (value: IRolCase) => Promise<IRolCase>;
  checkIfReferenceAvailable: (
    value: ICheckIfReferenceAvailableRequest
  ) => Promise<ICheckIfReferenceAvailableResponse>;
  checkReportState: (value: ICaseRequest) => Promise<ICodeResponse>;
}

export interface IVerifyApi {
  getCases: (request: ICasesRequest) => Promise<IVerifyCasesResponse>;
  getCase: (request: ICaseRequest) => Promise<ICase>;
  addCase: (request: ICase) => Promise<ICase>;
  getJobs: () => Promise<IJobsResponse[]>;
  getStats: () => Promise<IStatsResponse>;
  activatePolicy: (request: ICaseRequest) => Promise<ICodeResponse>;
  checkIfReferenceAvailable: (
    request: ICheckIfReferenceAvailableRequest
  ) => Promise<ICheckIfReferenceAvailableResponse>;
  cancelPolicy: (request: ICaseRequest) => Promise<ICodeResponse>;
  getExcele: (request: IVerifyCaseExcelRequest) => Promise<any>;
  archiveCase: (request: IArchiveRequest) => Promise<void>;
  unArchiveCase: (request: IArchiveRequest) => Promise<void>;
}

export interface IPropCheckerApi {
  getSessions: (request: ISessionsRequest) => Promise<IPropCheckerSession[]>;
  getSession: (request: ISessionRequest) => Promise<IPropCheckerSession>;
  createSession: (
    request: IPropCheckerCreateRequest
  ) => Promise<IPropCheckerSession>;
  updateSession: (
    request: IPropCheckerUpdateRequest
  ) => Promise<IPropCheckerSession>;
  checkIfReferenceAvailable: (
    value: IReferenceRequest
  ) => Promise<ICheckIfReferenceAvailableResponse>;
  archiveSession: (request: IArchiveRequest) => Promise<void>;
  unArchiveSession: (request: IArchiveRequest) => Promise<void>;
  getDataDictionary: (requst: IDataDictionaryRequest) => Promise<any>;
  setMapSession: (requst: ISetMapSessionRequest) => Promise<void>;
}

export interface INewBuildApi {
  getSessions: (request: IExtReferenceRequest) => Promise<INewBuildSession[]>;
  getSession: (request: ISessionRequest) => Promise<INewBuildSession>;
  createSession: (request: INewBuildCreateRequest) => Promise<INewBuildSession>;
  updateSession: (request: INewBuildUpdateRequest) => Promise<INewBuildSession>;
  getDevelopmentsByPostcode: (
    request: IDevelopmentsByPostcodeRequest
  ) => Promise<IDevelopment[]>;
  getDevelopmentsByTown: (
    request: IDevelopmentsByTownRequest
  ) => Promise<IDevelopment[]>;
  getDevelopment: (id: number) => Promise<IDevelopment_NEW>;
  getPlot: (idPlot: number) => Promise<IPlotFull>;
  missed: (request: FormData) => Promise<void>;
  checkIfReferenceAvailable: (
    value: ICheckIfReferenceAvailableRequest
  ) => Promise<ICheckIfReferenceAvailableResponse>;
  getDevelopers: () => Promise<IDeveloper[]>;
}

export interface IAPVSApi {
  addUpdateSession: (
    value: IApvsSessionRequest
  ) => Promise<APIResponse<IApvsSessionResponse | null>>;
  archiveSession: <T>(value: string) => Promise<APIResponse<T | null>>;
  cancelSession: <T>(
    id: string,
    reason: string
  ) => Promise<APIResponse<T | null>>;
  checkIfReferenceAvailable: (
    value: ICheckIfReferenceAvailableRequest
  ) => Promise<ICheckIfReferenceAvailableResponse>;
  getSessions: (
    value: IApvsSearchRequest
  ) => Promise<APIResponse<IApvsSessionResponse[] | null>>;
  getSession: (value: string) => Promise<IApvsSessionResponse>;
}

export interface IWatcherApi {
  addWatcher: (request: IWatcher) => Promise<IWatcher>;
  getWatchers: (request?: ISessionsRequest) => Promise<IWatcherResponse>;
  archiveWatcher: (uid: string) => Promise<void>;
}

//#region Login
export interface IUserInfo {
  id?: string;
  first_name: string;
  last_name: string;
  email?: string;
  password?: string;
  mobile?: string;
  is_email_confirmed?: boolean;
  is_mobile_confirmed?: boolean;
  added?: Date;
  role?: string;
}

export enum GrantType {
  Password = "password",
  RefreshToken = "refresh_token",
  AuthorizationCode = "authorization_code",
}

export enum ClientId {
  O365 = "portal.o365",
  ApiClient = "portal.api.client",
}

export interface IRefreshTokenRequest {
  client_id: ClientId;
  client_secret?: string;
  grant_type: GrantType;
  refresh_token: string;
}

export interface IGetTokenRequest {
  client_id: ClientId;
  client_secret?: string;
  grant_type: GrantType;
  username: string;
  password: string;
}

export interface IGetTokenByCodeRequest {
  client_id: ClientId;
  client_secret?: string;
  grant_type: GrantType;
  code: string;
  redirect_uri: string;
}

export type IAuthRequest =
  | IRefreshTokenRequest
  | IGetTokenRequest
  | IGetTokenByCodeRequest;

export interface IBasicAuth {
  username: string;
  password: string;
}

export interface IRefreshTokenResponse {
  id_token?: string;
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}

export interface IRevokeTokenRequest {
  token: string;
  token_type_hint: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
}

export interface IModule {
  module: string;
  unlimitedPlan: boolean;
  allowUnderwritingMode: boolean;
  allowOrderReportMode: boolean;
}

export interface IUser {
  auth: string;
  user_id: string;
  email: string;
  account_id: string;
  account_name: string;
  first_name: string;
  last_name: string;
  modules: IModule[];
  is_first_login: boolean;
  is_cls_user: boolean;
  is_account_admin: boolean;
  allow_view_company_sessions: boolean;
  order_credits_remaining: number;
  order_limit_date: Date;
  has_account_limit: boolean;
}

export interface IAccountUserModel {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  is_disabled: boolean;
  isEmailConfirmed: boolean;
}

export interface IAccountUsersResponse {
  accountUsers: IAccountUserModel[];
}

export interface IRegisterRequest {
  first_name: string;
  last_name: string;
  mobile: string;
  email: string;
  company_name: string;
  position: string;
  password: string;
  //hearAboutUs: number
}

export interface IRequestResetPasswordRequest {
  email: string;
}
export interface IRequestResetPasswordResponse {
  token: string;
}

export interface IResetPasswordRequest {
  password: string;
  token: string;
}

export interface IVerifyEmailRequest {
  token: string;
}

export interface IUpdatePasswordRequest {
  password: string;
  current_password: string;
}

export interface ICodeResponse {
  code: string;
}
//#endregion

export interface IPad {
  AddressKey: string;
  Address: string;
  Postcode: string;
  Tenure: string;
  PropertyType: string;
  Bedrooms: number;
  Bathrooms: number;
  Receptions: number;
  LastListedClass: string;
  LastListedType: string;
  LastListedDate: string;
  LastListedQualifier: string;
  LastListedPrice: number;
  LastListedFloorPlanUrl: string;
  ListingFullDetail: string;
  ImageUrl1: string;
  ImageUrl2: string;
  ImageUrl3: string;
  ImageUrl4: string;
  ImageUrl5: string;
  Images: string[];
}

export enum SessionsType {
  My,
  Account,
}

export interface ISharedSessions {
  sessionsType: SessionsType;
  filter?: boolean;
}

export interface ISessionsRequest extends ISharedSessions {
  search?: string;
  limit?: number;
  offset?: number;
  archived: boolean;
}
export interface ISessionsResponse {
  sessions: ISession[];
}
export interface ISession {
  account_id: string;
  active: boolean;
  added: Date;
  archived: boolean;
  audit_id: string;
  id: string;
  module_id: string;
  reference: string;
  user_id: string;
  last_update_user: string;
  underwriting_mode: boolean;
  order_report_mode: boolean;
  session_status?:
    | "NotSet"
    | "New"
    | "Ready"
    | "Processing"
    | "Pass"
    | "Fail"
    | "Referred"
    | "Error";
}
export interface IWorkloadsRequest {
  ModuleId?: string;
  SessionId?: string;
  IncludeClosed?: boolean;
  SessionReference?: string;
  WorkloadSessionId?: string;
}
export interface IWorkloadsResponse {
  workloads: IWorkload[];
}

export interface IWorkload {
  Id: string;
  ModuleId: number;
  SessionAccountId: number;
  SessionId: string;
  SessionReference: string;
  ProductName: string;
  ProductResult: string;
  State: string;
  Added: string;
  LastAccessed?: Date | string;
  Approved?: Date | string;
  Closed?: boolean;
  User?: string;
  UserId?: string;
  WorkloadSessionId: string;
}

export interface IArchiveRequest {
  sessionId: string;
}

export interface IModuleDataRequest {
  moduleId: ModuleTypes;
}

export interface IPrintCostRequest {
  layerIds: string[];
  areaInKmSq: number;
  module: ModuleTypes;
}

export interface IPrintCostResponse {
  totalPrice: number;
}

export interface IModuleDataResponse {
  groups: IGroup[];
  profile: string;
}

export interface IGroup {
  id: string;
  is_active: boolean;
  is_deleted: boolean;
  layers: ILayer[];
  name: string;
  title: string;
}

export interface ILayerDropdown {
  key: string;
  value: string;
  is_active: boolean;
}

export interface ILayer {
  copyright: string;
  display: string;
  group_id: string;
  id: string;
  is_active: boolean;
  is_group: boolean;
  is_queryable: boolean;
  ident_text?: string;
  name: string;
  parent_id: string;
  payload: { filters: IFilter[] };
  rank: number;
  should_log: boolean;
  title: string;
  dropdown?: ILayerDropdown[] | null;
}

export interface ICasesRequest extends ISharedSessions {
  search: string;
  archived?: boolean;
}

export interface IRolCasesResponse {
  cases: IRolCase[];
}

export interface IRolCase {
  Added?: Date;
  DDAJobId?: number;
  Id?: string;
  Reference: string;
  State?: string;
  Titles: IRolTitle[];
  lastEdited?: Date;
  session_id?: string;
  Payload?: any;
  Save?: boolean;
  last_update_user?: string;
}

export interface IRolTitle {
  id?: number;
  reference: string;
  tenure: TenureType;
  title_no: string;
  type: string;
}

export enum TenureType {
  Freehold = "Freehold",
  Leasehold = "Leasehold",
  Rentcharge = "Rentcharge",
  FreeholdPendreInGross = "Freehold profit a prendre in gross",
  LeaseholdPendreInGross = "Leasehold profit a prendre in gross",
  Manor = "Manor",
  FreeholdAffectingFranchise = "Freehold affecting franchise",
  FreeholdRelatingFranchise = "Freehold relating franchise",
  CommonholdFreehold = "Commonhold Freehold",
  CommonholdLeasehold = "Commonhold Leasehold",
  SchemeTitleLeasehold = "Scheme Title Leasehold",
  SchemeTitleFreehold = "Scheme Title Freehold",
  Caution = "Caution",
  Pending = "Pending first registration",
  Unknown = "Unknown",
}

export interface IVerifyCasesResponse {
  cases: IVerifyCase[];
}

export interface IVerifyCase {
  Added: Date;
  Address: IAddress;
  DepositAmount: number;
  Id: string;
  Payload: IPayload;
  PurchasePrice: number;
  QuoteExpiry: Date;
  Reference: string;
  State: string;
  last_update_user: string;
}

export interface IAddress {
  ADDRESS_KEY: string;
  Added: Date;
  AddressKey: string;
  BuildingName: string;
  BuildingNumber: string;
  Country: string;
  County: string;
  Easting: number;
  Id: string;
  Northing: number;
  PostTown: string;
  Postcode: string;
  SubBuildingName: string;
  ThoroughfareName: string;
  DependentThoroughfareName: string;
  OrganisationName: string;
  TitleNumbers: string[];
  Uprn: string;
  AddressString: string;
}

export interface ITitle {
  ADDRESS_KEY: string;
  Titles: {
    TitleNumber: string;
    Tenure: string;
    ClassOfTitle: string;
    ClassOfTitleDescription: string;
    EstateInterest: string;
    EstateInterestDescription: string;
    TenureRank: number;
    SelectionMethod: string;
    Address: ITitleAddress;
    Status?: string;
    OtherTitles: {
      TitleNumber: string;
      Tenure: string;
    }[];
  }[];
}

export interface ILender {
  LenderId: string;
  Name: string;
}

export interface ITitleAddress {
  SubBuildingName?: any;
  BuildingNumber: string;
  BuildingName?: any;
  Street: string;
  Town: string;
  PostCode: string;
}

export interface IExtendedAddress extends IAddress {
  Id: string;
  PoBox: string;
  DependentLocality: string;
  DoubleDependantLocality: string;
  DepartmentName: string;
}

export interface IPlace {
  Id: number;
  Type: string;
  Name: string;
  Rank: number;
  Easting: number;
  Northing: number;
}

export interface IPayload {
  DepositAmount: string;
  PurchasePrice: string;
  TitleNumber: string;
}

export interface IJobsResponse {
  Id: string;
  Reference: string;
  State: string;
  Filename: string;
  Data: string;
  Items: IBatch[];
}

export interface IBatch {
  Id: string;
  State: string;
  Address: string;
  Postcode: string;
  TitleNumber: string;
  Error: string;
  DepositAmount: number;
  PurchasePrice: number;
  CaseId: string;
}

export interface IStatsResponse {
  ActiveInPrinciple: number;
  ActiveVerifyPolicies: number;
  AverageLodgementTimeDays: number;
  VerifyChecks: number;
}

export interface ILookupAddressRequest {
  ADDRESS_KEY?: string;
  postcode?: string;
  titleNumber?: string;
  WKT?: string;
  uprn?: string;
}

export interface IPostcode {
  postcode: string;
  Easting: number;
  Northing: number;
  country: string;
}

export interface ILookupPlaceRequest {
  search: string;
}

export interface ICheckIfReferenceAvailableRequest {
  id?: string;
  reference: string;
}

export interface ICheckIfReferenceAvailableResponse {
  reference: string;
  code: CheckReferenceCodeType;
}

export enum CheckReferenceCodeType {
  ERROR = "ERROR",
  AVAILABLE = "AVAILABLE",
}

export interface ICasePayload {
  PurchasePrice: string;
  DepositAmount: string;
  TitleNumber: string;
  ThirdPartyOrderId?: string;
}

export interface ICaseAttributes {
  ADDRESS_KEY: string;
  PERIOD_BUILT: string;
  BUILDING_TYPE: string;
  BUILDING_DESCRIPTION: string;
  DWELLING_TYPE: string;
  DWELLING_DESCRIPTION: string;
  BEDROOMS: string;
  BATHROOMS: string;
  RECEPTIONS: string;
  WALL_TYPE: string;
  WALL_MATERIAL: string;
  ROOF_SHAPE: string;
  BUILDING_HEIGHT: string;
  BUILDING_STOREY_COUNT: string;
  PROPERTY_BUILT_IN_YEAR: string;
  PropertyAttributeData: any;
}

export interface ICase {
  Id?: string;
  AccountId?: number;
  Reference: string;
  PurchasePrice?: number;
  DepositAmount?: number;
  QuoteUid?: string;
  QuoteExpiry?: Date;
  PolicyExpiry?: Date;
  PolicyActivated?: Date;
  PolicyActivatedBy?: string;
  State?: string;
  Added?: Date;
  Address?: IAddress;
  Payload: ICasePayload;
  CapitalValuation?: number;
  Vsm?: number;
  VsmConfidence?: number;
  EpmMean?: number;
  EpmSd?: number;
  LodgementCheck?: Date;
  LodgementStatus?: boolean;
  Attributes?: ICaseAttributes;
}

export interface IVerifyCaseExcelRequest {
  cases: IVerifyCaseExcel[];
}

export interface IVerifyCaseExcel {
  Address: string;
  Title: string;
  Reference: string;
  State: string;
  SavedBy: string;
  Added: string;
}

export interface ICaseRequest {
  caseId: string;
}

export interface IConveymapSession {
  id?: string;
  account_id?: string;
  user_id?: string;
  reference: string;
  active?: boolean;
  archived?: boolean;
  audit_id?: string;
  filters?: IFilter[];
  layers: ConveyMapSessionViewLayer[];
  base: ConveyMapSessionViewLayer;
  historic_layers: ConveyMapSessionViewLayer[];
  position: number[];
  zoom: number;
  added?: Date;
  module_id: ModuleTypes;
  features: ISessionFeature[];
  underwriting_mode: boolean;
  order_report_mode: boolean;
  session_underwriting: IUnderwritingSession | null;
  session_order_report: IOrderReportSession | null;
  session_status?: string;
}

export enum ModuleTypes {
  ConveyMap = 1,
  RightsOfLight = 2,
  Verify = 3,
  NewBuild = 4,
  PropertyChecker = 5,
}

export interface ISessionFeature {
  id?: string;
  account_id: string;
  user_id: string;
  session_id: string;
  title: string;
  description: string;
  payload: string;
  date_added: Date;
  wkt: string;
  link_id: string;
  is_visible: boolean;
  is_measure: boolean;
  fill?: string;
  opacity?: number;
}

export interface ConveyMapSessionViewLayer {
  is_grey_scale?: boolean;
  layer_id: string;
  opacity: number;
  display_meta?: boolean;
  filters?: IFilterValue[];
  dropdown?: { key: string; value: string; is_active: boolean }[];
}

export interface IFilter {
  type: FilterType;
  field: string;
  options: IOption[];
}

export interface IOption {
  title: string;
  value: string;
}

export enum FilterType {
  Selection = "SELECTION",
  Text = "TEXT",
  Date = "DATE",
  Multitext = "MULTITEXT",
  ILike = "SELECTION_ILIKE",
  Boolean = "BOOLEAN",
}

export interface IFilterValue {
  group?: string;
  title?: string;
  type: FilterType;
  field: string;
  value?: string;
  from?: string;
  to?: string;
  searchTerms?: ISerachTerm[];
}

export enum TermOperators {
  And = "And",
  Or = "Or",
  Not = "Not",
}

export interface ISerachTerm {
  value: string;
  type: TermOperators;
}

export enum UnderwritingState {
  NewState = "new",
  OrderState = "order",
  ReadyState = "ready",
}

export enum UnderwritingTitleGroup {
  A = 1,
  B = 2,
}

export interface IUnderwritingSession {
  SessionId?: string;
  State: UnderwritingState;
  Added?: Date;
  LastEdited?: Date;
  DDAJobId?: number;
  GroupAName?: string;
  GroupBName?: string;
  Titles: IUndewritingTitleSession[];
  PlaceDDAOrder: boolean;
  ShowOnAllTabs: boolean;
  HideAllTitles?: boolean;
  HideAllGroups?: boolean;
}

export interface IOrderReportSessionProducts {
  State?: string;
  Added?: Date;
  ReportProductId: number;
  ReportProductName?: string;
  ReportProductPrice?: number;
  OrderRequested?: Date;
  DataApiClientRef?: string;
  DataApiOrderId?: any;
}
export interface IOrderReportSession {
  State?: string;
  TitleNumber?: string | null;
  Tenure?: string | null;
  WKT?: string | null;
  Client?: string | null;
  ProjectId?: string | null;
  Insurer?: string | null;
  CcReport?: string | null;
  PlaceOrders?: boolean;
  Address?: string | null;
  ProductLimitOverrided?: boolean | null;
  Products?: IOrderReportSessionProducts[] | null;
}

export interface IAddOrderReportTitle {
  Tenure?: string | null;
  TitleNumber?: string | null;
  WKT?: string | null;
  address?: string | null;
}

export interface IAddOrderReportDetails {
  Tenure?: string | null;
  TitleNumber?: string | null;
  WKT?: string | null;
  Client?: string | null;
  ProjectId?: string | null;
  Insurer?: string | null;
  CcReport?: string | null;
  PlaceOrders?: boolean;
  ProductLimitOverrided?: boolean;
  Address?: string;
}

export interface IUndewritingTitleSession {
  Id?: string;
  SessionId?: string;
  TitleNumber: string;
  Tenure?: string;
  WKT?: string;
  Address?: string;
  Group?: UnderwritingTitleGroup;
  LabelName?: string;
  Fill?: string;
  Opacity?: number;
  Added?: Date;
  LastEdited?: Date;
  Highlight?: boolean;
  TitleHidden?: boolean;
}

export type IViewLayer = ILayer & {
  visible: boolean;
  opacity: number;
  filter?: string;
  zIndex?: number;
};

export interface IFeatureInfo {
  crs?: { properties: any; type: string };
  features: IFeature[];
  numberReturned: number;
  timeStamp: Date;
  totalFeatures: string;
  type: string;
}

export interface IFeature {
  geometry: IGeometry;
  geometry_name: string;
  id: string;
  properties: any;
  type: string;
}

export interface IGeometry {
  coordinates: number[] | number[][][];
  type: string;
}

export interface INewBuildSession {
  id: string;
  reference: string;
  session_id: string;
  updated?: Date;
  address?: string;
  phase?: number;
  plot?: number;
  development?: IDevelopment_NEW;
  last_update_user?: string;
}

export enum WatcherType {
  Planning = 1,
  BuildingControl = 2,
  Title = 3,
  EnergyPerformance = 4,
  CouncilTaxBand = 5,
}

export interface IWatcher {
  Id?: string;
  ModuleId: ModuleTypes;
  WatcherTypeId: WatcherType;
  WatcherDistance?: number;
  Easting?: number;
  Northing?: number;
  ADDRESS_KEY?: string;
  Address?: string;
  TitleNumber?: string;
  TotalApplications?: string;
  LastChange?: Date;
  State: string;
  Added: Date;
  SessionId: string;
  SessionReference: string;
  FeatureId?: string;
}

export interface IWatcherResponse {
  watchers: IWatcher[];
}

export interface IPropCheckerSession {
  id: string;
  reference: string;
  map_session_id?: string;
  updated?: Date;
  address: IAddress;
  last_update_user?: string;
}

export enum RouteType {
  Drive = 1,
  Walk = 2,
  Cycle = 3,
}

export interface IIsochroneRequest {
  type: RouteType;
  easting: number;
  northing: number;
  minutes: number;
}

export interface IIsochroneResult {
  routeType: RouteType;
  seconds: number;
  isochroneGeometryWkt: string;
}

export interface IPropCheckerCreateRequest {
  reference: string;
  //map_session_id: string;
  address: IAddress;
}

export interface IExtReferenceRequest extends ISharedSessions {
  reference: string;
}

export interface IReferenceRequest {
  reference: string;
}

export interface IDataDictionaryRequest {
  addressKey: string;
  sessionId?: string;
  serviceName?: string;
}

export interface ISetMapSessionRequest {
  uid: string;
  mapSessionUid: string;
}

export interface ISessionRequest {
  uid: string;
}

export interface IPropCheckerUpdateRequest {
  uid: string;
  reference: string;
}

export interface INewBuildCreateRequest {
  development_id: number;
  phase?: number;
  plot?: number;
  reference: string;
  map_session_id: string;
}

export interface INewBuildUpdateRequest {
  id: string;
  value: INewBuildCreateRequest;
}

export interface IDevelopmentsByPostcodeRequest {
  postcode: string;
}

export interface IDevelopmentsByTownRequest {
  town: string;
}

export interface IPhase_NEW {
  number: number;
  actual_phase: string;
  wkt: string;
  plots: IPlot_NEW[];
}

export interface IPlot_NEW {
  id: number;
  number: string;
  wkt: string;
}

export interface IPlotFull {
  id: number;
  number: string;
  wkt: string;
  advertised_price: string;
  housing_type: string;
  development_id: number;
  floor_plans: string[];
  property: IPlotPropery;
  development_phase: string;
  development_website: string;
  development_site_plan_image: string;
  development_site_plan_image_cache: string;
  development_developer: string;
  development_name: string;
  development_address: string;
  development_town: string;
  development_postcode: string;
}

export interface IPlotPropery {
  id: number;
  name: string;
  type: string;
  description: string;
  spec: string;
  bedrooms: string;
  bathrooms: string;
  en_suites: string;
  price_from: string;
  price_to: string;
  floor_space: string;
  square_footage: string;
  parking: string;
  cleaned_property_type_key: number;
  cleaned_property_type: string;
  cleaned_bedrooms: number;
  cleaned_bathrooms: number;
  cleaned_en_suites: number;
  cleaned_parking: string;
  floor_plans: string[];
}

export interface IDevelopment {
  developer: string;
  id: number;
  name: string;
  address: string;
  postcode: string;
}

export interface IDeveloper {
  Id: number;
  Name: string;
  WebPageUrl: string;
}

export interface IDevelopment_NEW {
  developer: string;
  id: number;
  name: string;
  address: string;
  town: string;
  postcode: string;
  phases: IPhase_NEW[];
  unassigned_plots: IPlot_NEW[];
  statistics: IDevelopmentStatistic;
}

export interface IDevelopmentStatistic {
  phases_total: number;
  plots_total: number;
  plots_free_market: number;
  plots_affordable: number;
  plots_social: number;
  bedrooms_min: number;
  bedrooms_max: number;
  website: string;
  property_types: string[];
}

export interface IWhat3WorldsResponse {
  words: string;
  country: string;
  square: {
    southwest: {
      lat: number;
      lng: number;
    };
    northeast: {
      lat: number;
      lng: number;
    };
  };
  coordinates: {
    lat: number;
    lng: number;
  };
  language: string;
}

export interface IWhat3WorldsRequest {
  words: string;
}

export interface IDataDictionary {
  Radon: {
    LastModified: Date;
    RadonPotentialClass: string;
    PercentageOfDwellingsExceedingRadonActionLevel: string;
  };
  PricePaid: {
    Price: 0;
    DateOfTransfer: Date;
  };
  RenewableEnergyDistances: {
    LastModified: Date;
    AdvancedConversionTechnologies: 0;
    AnaerobicDigestion: 0;
    BiomassCofiring: 0;
    BiomassDedicated: 0;
    EfwIncineration: 0;
    HotDryRocks: 0;
    LandfillGas: 0;
    LargeHydro: 0;
    SewageSludgeDigestion: 0;
    ShorelineWave: 0;
    SmallHydro: 0;
    TidalBarrageAndTidalStream: 0;
    WindOffshore: 0;
    WindOnshore: 0;
    ElectricitySubstations: 0;
    Pylons: 0;
    WindTurbines: 0;
    SolarFarms: 0;
    SolarFarms_PlanningPermissionGranted: string;
    SolarFarms_PlanningPermissionExpired: string;
    SolarFarms_UnderConstruction: string;
    SolarFarms_Operational: string;
  };
  BroadbandSpeeds: {
    MedianDownloadMbps: string;
    MaximumDownloadMbps: string;
    AverageDownloadMbps: string;
  };
  Schools: [
    {
      Distance: 0;
      SixthForm: string;
      SecondaryEducation: string;
      PrimaryEducation: string;
      Nursery: string;
      EstablishmentName: string;
      TypeOfEstablishment: string;
      StatutoryLowAge: string;
      StatutoryHighAge: string;
      Boarders: string;
      Gender: string;
      ReligiousCharacter: string;
      SchoolCapacity: string;
      NumberOfPupils: string;
      OfstedRating: string;
      OfstedLastInspection: string;
    },
  ];
  OverseasCorporateOwnerships: {
    Id: number;
    TitleNumber: string;
    Tenure: string;
    PropertyAddress: string;
    District: string;
    County: string;
    Region: string;
    Postcode: string;
    MultipleAddressIndicator: string;
    PricePaid: string;
    ProprietorName1: string;
    CompanyRegistrationNo1: string;
    ProprietorshipCategory1: string;
    CountryIncorporated1: string;
    Proprietor1Address1: string;
    Proprietor1Address2: string;
    Proprietor1Address3: string;
    ProprietorName2: string;
    CompanyRegistrationNo2: string;
    ProprietorshipCategory2: string;
    CountryIncorporated2: string;
    Proprietor2Address1: string;
    Proprietor2Address2: string;
    Proprietor2Address3: string;
    ProprietorName3: string;
    CompanyRegistrationNo3: string;
    ProprietorshipCategory3: string;
    CountryIncorporated3: string;
    Proprietor3Address1: string;
    Proprietor3Address2: string;
    Proprietor3Address3: string;
    ProprietorName4: string;
    CompanyRegistrationNo4: string;
    ProprietorshipCategory4: string;
    CountryIncorporated4: string;
    Proprietor4Address1: string;
    Proprietor4Address2: string;
    Proprietor4Address3: string;
    DateProprietorAdded: string;
    AdditionalProprietorIndicator: string;
    ChangeDate: string;
    ChangeIndicator: string;
    AddressKeys: null;
  }[];
  CorporateOwnerships: [
    {
      Id: 0;
      TitleNumber: string;
      Tenure: string;
      PropertyAddress: string;
      District: string;
      County: string;
      Region: string;
      Postcode: string;
      MultipleAddressIndicator: string;
      PricePaid: string;
      ProprietorName1: string;
      CompanyRegistrationNo1: string;
      ProprietorshipCategory1: string;
      Proprietor1Address1: string;
      Proprietor1Address2: string;
      Proprietor1Address3: string;
      ProprietorName2: string;
      CompanyRegistrationNo2: string;
      ProprietorshipCategory2: string;
      Proprietor2Address1: string;
      Proprietor2Address2: string;
      Proprietor2Address3: string;
      ProprietorName3: string;
      CompanyRegistrationNo3: string;
      ProprietorshipCategory3: string;
      Proprietor3Address1: string;
      Proprietor3Address2: string;
      Proprietor3Address3: string;
      ProprietorName4: string;
      CompanyRegistrationNo4: string;
      ProprietorshipCategory4: string;
      Proprietor4Address1: string;
      Proprietor4Address2: string;
      Proprietor4Address3: string;
      DateProprietorAdded: string;
      AdditionalProprietorIndicator: string;
      ChangeDate: Date;
      ChangeIndicator: string;
      AddressKeys: string;
    },
  ];
  RegisteredLeases: [
    {
      UniqueIdentifier: string;
      Tenure: string;
      RegisterPropertyDescription: string;
      County: string;
      Region: string;
      AssociatedPropertyDescriptionID: string;
      AssociatedPropertyDescription: string;
      UPRN: string;
      PricePaid: string;
      RegOrder: string;
      DateOfLease: Date;
      Term: string;
      AlienationClauseIndicator: string;
      ChangeIndicator: string;
      ChangeDate: Date;
    },
  ];
  HistoricLandfill: {
    LastModified: Date;
    Distance: number;
    Source: string;
    SiteName: string;
    SiteAddress: string;
    LicenseHolder: string;
  };
  WaterDistances: {
    LastModified: Date;
    SurfaceWaterArea: number;
    InlandWaterLine: number;
    DrainLine: number;
    Culvert: number;
    ShoreLine: number;
    MarshArea: number;
  };
  Perils: {
    LastModified: Date;
    FloodScore: string;
    FloodInsureIssue: string;
    GroundStability: string;
    GroundStabilityText: string;
    PartIIa: string;
    MiningCoal: string;
    MiningCheshireBrine: string;
    JBAInsurability: 0;
    JBAInsurabilityDistance: 0;
    Fracking: 0;
    EnergyExplorationLicensedSite: 0;
    EnergyExplorationRoundLicenseOffered: 0;
    EnergyExplorationCarbonCaptureStorage: 0;
    EnergyExplorationBlockedOffered: 0;
    EnergyExplorationSubArea: 0;
    EnergyExplorationUnderGroundCoalClassification: string;
    EnergyExplorationNonEcoPower: string;
    EnergyExplorationEcoPower: string;
    SubRiskRating: 0;
    SubRiskCategory: string;
    SubRiskCode: 0;
    HS2_Tunnel: 0;
    HS2_Surface: 0;
    HS2_Station: 0;
    HS2_MaxSpeed: 0;
    CrossRail_Tunnel: 0;
    CrossRail_Surface: 0;
    CrossRail_Station: 0;
    ShrinkSwellSubsidenceRisk: string;
    BaselineClaySubsidenceRisk: string;
    Future2030sClaySubsidenceRisk: string;
    Future2050sClaySubsidenceRisk: string;
  };
  RailNoise: {
    LastModified: Date;
    Rail_24hr: string;
    Rail_7am_11pm_average: string;
    Rail_11pm_7am_average: string;
  };
  RoadNoise: {
    LastModified: Date;
    Road_24hr: string;
    Road_7am_11pm_average: string;
    Road_11pm_7am_average: string;
  };
  ConservationArea: {
    SPA: string;
    SSSI: string;
    AONB: string;
  };
  CoastalErosion: {
    LastModified: Date;
    Natural_Distance: string;
    Natural_DefenceType: string;
    Natural_FeatureType: string;
    Natural_0_to_20_Year_Worst_Case_Movement_Distance: string;
    Natural_0_to_20_Year_Best_Case_Movement_Distance: string;
    Natural_20_to_50_Year_Worst_Case_Movement_Distance: string;
    Natural_20_to_50_Year_Best_Case_Movement_Distance: string;
    Natural_50_to_100_Year_Worst_Case_Movement_Distance: string;
    Natural_50_to_100_Year_Best_Case_Movement_Distance: string;
    Defended_Distance: string;
    Defended_DefenceType: string;
    Defended_FeatureType: string;
    Defended_0_to_20_Year_Shoreline_Management_Plan_Policy: string;
    Defended_0_to_20_Year_Worst_Case_Movement_Distance: string;
    Defended_20_to_50_Year_Shoreline_Management_Plan_Policy: string;
    Defended_20_to_50_Year_Worst_Case_Movement_Distance: string;
    Defended_50_to_100_Year_Shoreline_Management_Plan_Policy: string;
    Defended_50_to_100_Year_Worst_Case_Movement_Distance: string;
  };
  Outbuildings: {
    Quantity: 0;
    TotalSqm: 0;
  };
  MultipleKitchens: string;
  Lift: boolean;
  UtilityRoom: boolean;
  ParkingTypes: {
    LastModified: Date;
    OffRoadParking: string;
    AssociatedParking: string;
    UndergroundParking: string;
    Driveway: string;
    Garage: string;
  };
  ListedBuildings: [
    {
      Source: string;
      Country: string;
      EntryId: string;
      EntryName: string;
      Grade: string;
      Designated: string;
      Hyperlink: string;
    },
  ];
  EnergyData: {
    DomesticCertificate: {
      BUILDING_REFERENCE_NUMBER: string;
      CURRENT_ENERGY_RATING: string;
      POTENTIAL_ENERGY_RATING: string;
      CURRENT_ENERGY_EFFICIENCY: string;
      POTENTIAL_ENERGY_EFFICIENCY: string;
      PROPERTY_TYPE: string;
      BUILT_FORM: string;
      INSPECTION_DATE: Date;
      LOCAL_AUTHORITY: string;
      CONSTITUENCY: string;
      COUNTY: string;
      LODGEMENT_DATE: Date;
      TRANSACTION_TYPE: string;
      ENVIRONMENT_IMPACT_CURRENT: string;
      ENVIRONMENT_IMPACT_POTENTIAL: string;
      ENERGY_CONSUMPTION_CURRENT: string;
      ENERGY_CONSUMPTION_POTENTIAL: string;
      CO2_EMISSIONS_CURRENT: string;
      CO2_EMISS_CURR_PER_FLOOR_AREA: string;
      CO2_EMISSIONS_POTENTIAL: string;
      LIGHTING_COST_CURRENT: string;
      LIGHTING_COST_POTENTIAL: string;
      HEATING_COST_CURRENT: string;
      HEATING_COST_POTENTIAL: string;
      HOT_WATER_COST_CURRENT: string;
      HOT_WATER_COST_POTENTIAL: string;
      TOTAL_FLOOR_AREA: string;
      ENERGY_TARIFF: string;
      MAINS_GAS_FLAG: string;
      FLOOR_LEVEL: string;
      FLAT_TOP_STOREY: string;
      FLAT_STOREY_COUNT: string;
      MAIN_HEATING_CONTROLS: string;
      MULTI_GLAZE_PROPORTION: string;
      GLAZED_TYPE: string;
      GLAZED_AREA: string;
      EXTENSION_COUNT: string;
      NUMBER_HABITABLE_ROOMS: string;
      NUMBER_HEATED_ROOMS: string;
      LOW_ENERGY_LIGHTING: string;
      NUMBER_OPEN_FIREPLACES: string;
      HOTWATER_DESCRIPTION: string;
      HOT_WATER_ENERGY_EFF: string;
      HOT_WATER_ENV_EFF: string;
      FLOOR_DESCRIPTION: string;
      FLOOR_ENERGY_EFF: string;
      FLOOR_ENV_EFF: string;
      WINDOWS_DESCRIPTION: string;
      WINDOWS_ENERGY_EFF: string;
      WINDOWS_ENV_EFF: string;
      WALLS_DESCRIPTION: string;
      WALLS_ENERGY_EFF: string;
      WALLS_ENV_EFF: string;
      SECONDHEAT_DESCRIPTION: string;
      SHEATING_ENERGY_EFF: string;
      SHEATING_ENV_EFF: string;
      ROOF_DESCRIPTION: string;
      ROOF_ENERGY_EFF: string;
      ROOF_ENV_EFF: string;
      MAINHEAT_DESCRIPTION: string;
      MAINHEAT_ENERGY_EFF: string;
      MAINHEAT_ENV_EFF: string;
      MAINHEATCONT_DESCRIPTION: string;
      MAINHEATC_ENERGY_EFF: string;
      MAINHEATC_ENV_EFF: string;
      LIGHTING_DESCRIPTION: string;
      LIGHTING_ENERGY_EFF: string;
      LIGHTING_ENV_EFF: string;
      MAIN_FUEL: string;
      WIND_TURBINE_COUNT: string;
      HEAT_LOSS_CORRIDOR: string;
      UNHEATED_CORRIDOR_LENGTH: string;
      FLOOR_HEIGHT: string;
      PHOTO_SUPPLY: string;
      SOLAR_WATER_HEATING_FLAG: string;
      MECHANICAL_VENTILATION: string;
      ADDRESS: string;
      LOCAL_AUTHORITY_LABEL: string;
      CONSTITUENCY_LABEL: string;
      LMK_KEY: string;
      ADDRESS1: string;
      ADDRESS2: string;
      ADDRESS3: string;
      POSTCODE: string;
      UPRN: string;
    };
    ScottishDomesticCertificate: {
      UPRN: string;
      Address1: string;
      Address2: string;
      PostTown: string;
      Postcode: string;
      DateOfAssessment: Date;
      TypeOfAssessment: string;
      DateOfCertificate: Date;
      TotalFloorAreaSqm: number;
      CurrentEnergyEfficiencyRating: number;
      CurrentEnergyEfficiencyRatingBand: string;
      PotentialEnergyEfficiencyRating: number;
      PotentialEnergyEfficiencyRatingBand: string;
      CurrentEnvironmentalImpactRating: number;
      CurrentEnvironmentalImpactRatingBand: string;
      PotentialEnvironmentalImpactRating: number;
      PotentialEnvironmentalImpactRatingBand: string;
    };
  };
  AddressSizes: {
    GardenSizeMSq: 0;
    TitleSizeMSq: 0;
    BuildingSizeMSq: 0;
  };
  UPRN: string;
  ADDRESS_KEY: string;
  PropertyAttributes: {
    ADDRESS_KEY: string;
    PERIOD_BUILT: string;
    BUILDING_TYPE: string;
    BUILDING_DESCRIPTION: string;
    DWELLING_TYPE: string;
    DWELLING_DESCRIPTION: string;
    BEDROOMS: string;
    BATHROOMS: string;
    RECEPTIONS: string;
    WALL_TYPE: string;
    WALL_MATERIAL: string;
    ROOF_SHAPE: string;
    BUILDING_HEIGHT: string;
    BUILDING_STOREY_COUNT: string;
    PROPERTY_BUILT_IN_YEAR: string;
    PROPERTY_TYPE: string;
    PropertyAttributeData: string;
  };
  PropertyImage: string;
  Planning: IFeature[];
  BuildingControl: IFeature[];
  PadCombinedPropertyModel: IPad;
}

export interface IOrderReportProducts {
  id: number;
  name: string;
  description: string;
  price?: number;
  is_active: boolean;
  allow_residential: boolean;
  allow_commercial: boolean;
  max_sqm?: number;
  max_addresses?: number;
}
export interface IOrderReportGroups {
  name: string;
  report_products: IOrderReportProducts[];
}

export interface IDownloadOrderReportRequest {
  sessionId: string;
  reportProductId?: string | number;
}

/* APVS TYPES */

export type IApvsStatus =
  | "NotSet" // Ignore
  | "New" // Case created and ready for "ConfirmCase"
  | "Processing" // Data processing, nothing can be done until this is finished
  | "Quoted" // Quoted (all data back) and ready for "AcceptQuote"
  | "Bespoke" // Awaiting further action internally
  | "Declined" // We can't offer an insurance, no further action possible
  | "Referred" // Awaiting further action internally
  | "Incepted" // Case has been incepted (quote accepted and inception date reached)
  | "Cancelled"
  | "Error";
export interface IApvsSessionRequest {
  id?: string;
  account_id?: string;
  user_id?: string;
  reference?: string;
  last_update_user?: string;
  case?: IApvsCase;
  properties?: IApvsProperty[];
  number_of_properties?: number;
  total_premium?: string;
  confirm_case?: boolean;
  accept_quote?: boolean;
  status?: IApvsStatus;
}
export interface IApvsSessionResponse {
  id?: string;
  account_id?: string;
  user_id?: string;
  reference?: string;
  added?: Date;
  archived: boolean;
  last_update_user: string;
  case?: IApvsCase;
  properties: IApvsProperty[] | [];
  number_of_properties: number;
  total_premium?: string;
  confirm_case?: boolean;
  accept_quote?: boolean;
  quote_date?: Date;
  status?: IApvsStatus;
}

export interface IApvsCase {
  loanReference: string;
  loanAmount: number;
  borrower: string;
  lender: string;
  new: boolean;
  refinance: boolean;
  productSwitch: boolean;
  cancellationReason?: string | null;
}

export interface IApvsProperty {
  id: string;
  added?: Date;
  title: ITitle | IApvsTitleSection;
  address?: IApvsAddress;
  details?: IApvsDetail;
}

export interface IApvsTitleSection {
  Titles: IApvsTitle[];
}

export interface IApvsTitle {
  TitleNumber: string;
  Tenure?: string;
  Added?: Date;
  Status?: IApvsStatus;
}

export interface IApvsAddress {
  AddressString?: string;
  DepartmentName?: string;
  OrganisationName?: string;
  SubBuildingName?: string;
  BuildingName?: string;
  PoBox?: string;
  BuildingNumber?: string;
  DependentThoroughfareName?: string;
  ThoroughfareName?: string;
  DoubleDependantLocality?: string;
  DependentLocality?: string;
  PostTown?: string;
  Postcode?: string;
  County?: string;
  Country?: string;
  Easting: number;
  Northing: number;
}

export interface IApvsDetail {
  propertyValuation: number;
  avmUsage: boolean;
  avmUsagePercentage?: number;
  desktopSurvey: boolean;
  physicalSurvey: boolean;
  statement: boolean;
  residentialC3a: boolean;
  residentialClassC: boolean;
  hmo: boolean;
  hmoA4Directions?: string | null;
  hmoLicenseRequired?: string | null;
  commercial: boolean;
}

export interface IApvsSearchRequest {
  search?: string;
  archived?: boolean;
}
