import { HTMLAttributes } from "react";
import "./loader.scss";

type Color = "primary" | "default";

interface ILoader extends HTMLAttributes<HTMLDivElement> {
  color?: Color;
}

const Loader = ({ color = "primary", ...props }: ILoader) => {
  return (
    <div className={`lds-roller ${color}`} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
