import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import { IWatcher, SessionsType } from "../../api/contracts";

export const getUserWatchers = createAsyncThunk(
  "conveymap/getUserWatchers",
  async (includeArchived: boolean) => {
    return await api.watcher.getWatchers({
      archived: includeArchived,
      sessionsType: SessionsType.My,
    });
  }
);

const watchlistSlice = createSlice({
  name: "watchlist",
  initialState: {
    watchers: [] as IWatcher[],
    loadWatchers: false,
  },
  reducers: {
    addWatcher: (state, action: PayloadAction<IWatcher>) => {
      const updatedWatchers = [...state.watchers, action.payload];
      return {
        ...state,
        watchers: updatedWatchers,
      };
    },
    archivedWatcher: (state, action: PayloadAction<string>) => {
      const updatedWatchers = state.watchers.map((item) => {
        if (item.Id === action.payload) {
          return {
            ...item,
            State: "archived",
          };
        }
        return item;
      });

      return {
        ...state,
        watchers: updatedWatchers,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserWatchers.fulfilled, (state, action) => {
      state.watchers = [...action.payload.watchers];
      state.loadWatchers = true;
    });
  },
});

export const { addWatcher, archivedWatcher } = watchlistSlice.actions;
export default watchlistSlice.reducer;
