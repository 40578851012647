import "./button.scss";
import React, { isValidElement, ReactNode } from "react";
import { ButtonHTMLAttributes } from "react";
import LoadingSpinner from "components/loading-spinner/loading-spinner";
import colorMap, { ColorName } from "util/color-map";
import AppIcon from "components/app-icon/app-icon";
import { IconName } from "util/icon-map";

type Color =
  | "primary"
  | "secondary"
  | "highlight"
  | "warning"
  | "danger"
  | "default"
  | "orange"
  | "disabled";

type IconPosition = "left" | "right";
type Size = "small" | "normal" | "large";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  backgroundColor?: ColorName | string;
  textColor?: ColorName | string;
  color?: Color | undefined /* This is a overall theme */;
  icon?: IconName | ReactNode;
  iconColor?: ColorName | string;
  iconPosition?: IconPosition;
  buttonSize?: Size;
  flat?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text = "",
  backgroundColor,
  textColor,
  color = "default",
  icon,
  iconColor,
  iconPosition = "right",
  buttonSize = "normal",
  flat = false,
  loading = false,
  ...props
}) => {
  const getIconColor = () => {
    if (iconColor) return iconColor;
    if (props.disabled) {
      return colorMap.TextDisabledColor;
    }

    switch (color) {
      case "disabled":
        return colorMap.TextDisabledColor;
      case "primary":
        return colorMap.DefaultColor;
      case "secondary":
        return colorMap.DefaultColor;
      case "highlight":
        return colorMap.DefaultColor;
      case "danger":
        return colorMap.DefaultColor;
      case "default":
        return colorMap.PrimaryColor;
      default:
        return iconColor || colorMap.PrimaryColor;
    }
  };

  const handleBackgroundColor = props.disabled
    ? colorMap.DisabledColor
    : colorMap[backgroundColor as ColorName] ||
      backgroundColor ||
      colorMap.PrimaryColor;

  return (
    <button
      {...props}
      style={{
        backgroundColor: !color ? handleBackgroundColor : undefined,
        ...props.style,
      }}
      className={`cls-btn ${color ? `cls-btn-${color}` : ""} cls-btn-${buttonSize} ${iconPosition} ${
        flat && "cls-btn-flat"
      } ${props.className ?? ""}`}
    >
      <span className="button-text">{text ? text : props.children}</span>
      {loading ? (
        <div>
          <LoadingSpinner size={18} color="secondary" />
        </div>
      ) : icon ? (
        typeof icon === "string" ? (
          <div className="cls-btn-icon">
            <AppIcon
              name={icon as IconName}
              size="100%"
              fill={getIconColor()}
            />
          </div>
        ) : (
          isValidElement(icon) && icon
        )
      ) : null}
    </button>
  );
};

export default Button;
