import { classNames } from "app/utils/common.utils";
import { InputHTMLAttributes } from "react";
import "./input.scss";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  helperText?: string;
}

const defaultProps: InputProps = {
  error: false,
  helperText: "",
};

function Input({ error, helperText, ...props }: InputProps) {
  const inputClasses = classNames({
    "input-field": true,
    error: error,
  });
  return (
    <div className={inputClasses}>
      <input {...props} />
      {helperText ? <div className="input-helper">{helperText}</div> : null}
    </div>
  );
}

Input.defaultProps = defaultProps;

export default Input;
