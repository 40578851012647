import { useEffect, useContext, useState } from "react";
import ApiContext from "api/api.context";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "./redux-hooks";
import notification from "app/helpers/notification";
import { IWorkloadsResponse } from "api/contracts";

/**
 * Listens to changes in the browser's history, specifically looking for navigation away or to a conveymap process page.
 * If user navigates to a conveymap process page and certain conditions are met it either marks as active or a notification will show it's active by another user
 * If the user navigates back from the conveymap process page and certain conditions are met, triggers the markInactive function.
 *
 * @returns {Function} - A cleanup function to unregister the history listener when the component is unmounted.
 */

const useWorkloadState = () => {
  const history = useHistory<{ session?: string; workload_session?: string }>();
  const api = useContext(ApiContext);
  const user = useAppSelector(({ userSlice }) => userSlice);
  const [url, setUrl] = useState<string | null>(null);

  const conveymapPattern = /\/conveymap\/process/i;
  const uidPattern = /\/conveymap\/process\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/i;

  /* TODO: Expand to work with other modules */

  const markInactive = async (id: string) => {
    await api.workload.markWorkloadInactive(id);
  };

  const markActive = async (id: string) => {
    await api.workload.markWorkloadActive(id);
  };

  const getAccountWorkloads = async (
    id: string
  ): Promise<IWorkloadsResponse | undefined> => {
    const response = await api.workload.getAccountWorkloads({
      WorkloadSessionId: id,
    });
    return response;
  };

  const checkSetIsActive = async () => {
    const current = history.location.pathname;
    if (current && user) {
      if (conveymapPattern.test(current)) {
        const uid = current.match(uidPattern);
        if (uid && uid[1]) {
          const response = await getAccountWorkloads(uid[1]);
          if (response && response.workloads.length) {
            const workload = response.workloads[0];
            const isActive = workload.State.toLocaleLowerCase() === "active";
            if (!isActive) markActive(workload.Id);
            if (isActive && workload.UserId !== user.user_id) {
              notification(
                "info",
                `User ${workload.User} is currently "${workload.State}" within this session.`
              );
            }
          }
        }
      }
    }
  };

  const checkSetInactive = () => {
    return history.listen(async (location, action) => {
      if (url) {
        const wasConveymap = conveymapPattern.test(url);
        if (action === "POP" && wasConveymap) {
          const uid = url.match(uidPattern);
          if (uid && uid[1]) {
            const response = await getAccountWorkloads(uid[1]);
            if (response && response.workloads.length) {
              const workload = response.workloads[0];
              const isActive = workload.State.toLocaleLowerCase() === "active";
              if (isActive && workload.UserId) {
                if (workload.UserId === user?.user_id) {
                  markInactive(workload.Id);
                }
              }
              return response;
            } else return null;
          }
        }
      }
      return setUrl(location.pathname);
    });
  };

  useEffect(() => {
    setUrl(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSetIsActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const unlisten = checkSetInactive();

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, url, user]);
};
export default useWorkloadState;
