import { lazy } from "react";
import paths from "../../app/route-paths";

const propertyCheckrConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.propertyChecker.processRoute,
      component: lazy(() => import("./map/map")),
    },
    {
      path: paths.propertyChecker.search,
      component: lazy(() => import("./search/prop-checkr-search")),
    },
    {
      path: paths.propertyChecker.resultRoute,
      component: lazy(() => import("./result/prop-checkr-result")),
    },
    {
      path: paths.propertyChecker.tab,
      component: lazy(() => import("./dashboard/prop-checkr.app")),
    },
  ],
};

export default propertyCheckrConfig;
