import { classNames } from "app/utils/common.utils";
import { HTMLAttributes } from "react";
import Modal from "../modal";
import "./modal-dialog.scss";

interface ModalDialogProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose?: () => void;
  backgroundDots?: boolean;
}

function ModalDialog({
  open,
  onClose,
  backgroundDots = false,
  children,
  ...props
}: ModalDialogProps) {
  if (!open) return null;

  const classes = classNames({
    "modal-overlay": true,
    "modal-blackout": true,
    "background-dots": backgroundDots,
  });

  function handleOnClose() {
    if (onClose) onClose();
  }

  return (
    <Modal scrolling={false}>
      <div className={classes} onClick={handleOnClose}>
        <div
          {...props}
          className={`modal-dialog ${props.className ?? ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
}

export function ModalHeader(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={`modal-header ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}

export function ModalTitle(props: HTMLAttributes<HTMLHeadingElement>) {
  return <h2 {...props}>{props.children}</h2>;
}

export function ModalBody(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={`modal-body ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}
export function ModalFooter(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={`modal-footer ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}

export default ModalDialog;
