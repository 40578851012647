import { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./modal.scss";

const modalRoot = document.getElementById("modal-root");

interface ModalProp {
  scrolling?: boolean;
}

const Modal: FC<ModalProp> = ({ children, scrolling = true }) => {
  const [el] = useState<HTMLElement>(document.createElement("div"));
  useEffect(() => {
    if (!scrolling) {
      const scrillbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrillbarWidth}px`;
    }
    modalRoot?.appendChild(el);
    return () => {
      if (!scrolling) {
        document.body.style.overflow = "unset";
        document.body.style.paddingRight = "0px";
      }
      modalRoot?.removeChild(el);
    };
  }, [el, scrolling]);

  return ReactDOM.createPortal(children, el);
};

export default Modal;
