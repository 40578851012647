import "./loading-spinner.scss";

type Color = "default" | "primary" | "secondary" | "highlight";

const LoadingSpinner = ({
  color = "default",
  size = 50,
}: {
  color?: Color;
  size?: number;
}) => {
  const spinnerStyle = {
    width: size,
    height: size,
  };

  return (
    <div
      className={`loading-spinner loading-spinner-${color}`}
      style={spinnerStyle}
    ></div>
  );
};

export default LoadingSpinner;
