import { lazy } from "react";
import paths from "./route-paths";

const routesConfig: Config = {
  routes: [
    {
      path: paths.landing,
      component: lazy(() => import("./landing-page/landing-page")),
    },
    {
      path: paths.login,
      component: lazy(() => import("./login/login/Login")),
    },
    {
      path: paths.resetpassword,
      component: lazy(
        () =>
          import("./login/reset-password/request-reset-password/reset-password")
      ),
    },
    {
      path: paths.newpassword,
      component: lazy(
        () => import("./login/reset-password/set-new-password/new-password")
      ),
    },
    {
      path: paths.verifyemail,
      component: lazy(() => import("./login/verify-email/verify-email")),
    },
    {
      path: paths.register,
      component: lazy(() => import("./login/register/Register")),
    },
    {
      path: paths.registerSuccess,
      component: lazy(
        () => import("./login/register/register-success/register-success")
      ),
    },
    {
      path: paths.registerFailed,
      component: lazy(
        () => import("./login/register/register-failed/register-failed")
      ),
    },
    {
      path: paths.contactUs,
      component: lazy(() => import("./contact-us/contact-us")),
    },
    {
      path: paths.creditsRunOut,
      component: lazy(
        () => import("./contact-us/credits-run-out/credits-run-out")
      ),
    },
    {
      path: paths.o365,
      component: lazy(() => import("./login/o365/o365-auth")),
    },
    {
      path: paths.findOutMore,
      component: lazy(() => import("./find-out-more/find-out-more")),
    },
    {
      authRequire: true,
      path: paths.triage,
      component: lazy(() => import("./triage/triage")),
    },
    {
      authRequire: true,
      path: paths.welcome,
      component: lazy(() => import("./welcome-page/welcome-page")),
    },
  ],
};

export default routesConfig;
