import { lazy } from "react";
import paths from "../../app/route-paths";

const newbuildConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.newBuild.processRoute,
      component: lazy(() => import("./map/map")),
    },
    {
      path: paths.newBuild.locator,
      component: lazy(() => import("./locator/locator")),
    },
    {
      path: paths.newBuild.support,
      component: lazy(() => import("./support/form/support-form")),
    },
    {
      path: paths.newBuild.tab,
      component: lazy(() => import("./dashboard/newbuild.app")),
    },
  ],
};

export default newbuildConfig;
