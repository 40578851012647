import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getCurrentUser, logout, refreshToken } from "./authSlice";
import paths from "../app/route-paths";
import Loading from "components/loader/loader";
import { add, format } from "date-fns";
import jwtService from "./jwtService";
import TimeoutDialog from "components/modal/timeout-dialog/timeout-dialog";
import { dateDiffInDays } from "app/helpers/date-helper";

const SHOW_DIALOG_TIME = 5; // in minutes
const GAP = 1; // in minutes

const withAuth = (WComponent: FC, authRequire?: boolean) => {
  const Authenticated: FC = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [load, setLoad] = useState(false);
    const user = useAppSelector((store) => store.userSlice);
    const [openDialog, setOpenDialog] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(
      null
    );
    const [time, setTime] = useState("");

    const onKeepSignIn = () => {
      if (timerInterval) clearInterval(timerInterval);
      setOpenDialog(false);
      refreshAccess(false);
      setTime("");
    };

    const onSignOut = async () => {
      await dispatch(logout());
      history.push(paths.login);
    };

    const refreshAccess = useCallback(
      async (withLoader: boolean) => {
        withLoader && setLoad(false);

        if (!jwtService.isSessionExist()) {
          const accessToken = jwtService.getAccessToken();
          if (accessToken) jwtService.setAccessToken(accessToken);
          if (!accessToken) {
            console.log("There is not access_token");
            history.push(paths.login);
            return;
          }
        }

        if (!user) await dispatch(getCurrentUser());

        if (user) {
          const trialDaysLeft = dateDiffInDays(
            new Date(),
            new Date(user?.order_limit_date)
          );
          const limitsExpired = user.has_account_limit && trialDaysLeft <= 0;
          if (limitsExpired) {
            history.push(paths.creditsRunOut);
          }
        }

        const response = await dispatch(refreshToken());
        if (response.payload) {
          const timeout = setTimeout(
            () => {
              const logoutIn = add(new Date(), { minutes: SHOW_DIALOG_TIME });
              const interval = setInterval(async () => {
                const diff = logoutIn.getTime() - new Date().getTime();
                if (diff < 0) {
                  await dispatch(logout());
                  history.push(paths.login);
                }
                setTime(format(diff, "m:ss"));
                setOpenDialog(true);
              }, 1000);
              setTimerInterval(interval);
            },
            (response.payload as any).expires_in * 1000 -
              (SHOW_DIALOG_TIME + GAP) * 60 * 1000
          );
          setTimer(timeout);
        }
        withLoader && setLoad(true);
      },
      [dispatch, history, user]
    );

    useEffect(() => {
      if (!authRequire) {
        setLoad(true);
        return;
      }

      refreshAccess(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [timer]);

    useEffect(() => {
      return () => {
        if (timerInterval) clearInterval(timerInterval);
      };
    }, [timerInterval]);

    if (!load) return <Loading />;

    return (
      <>
        <WComponent />
        <TimeoutDialog
          onKeepSignIn={onKeepSignIn}
          onSignOut={onSignOut}
          open={openDialog}
          time={time}
        />
      </>
    );
  };

  return Authenticated;
};

export default withAuth;
