import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { login } from "auth/authSlice";
import Button from "components/form/button/button";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import routePaths from "../../route-paths";
import "./login-form.scss";
import ServiceContext from "service/service-context";
import { useContext, useState } from "react";
import PrimaryInput from "components/form/input/styled/primary/primary-input";
import { classNames } from "app/utils/common.utils";
import { ClientId, IUser } from "api/contracts";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { validationMessages } = useContext(ServiceContext);
  const [failed, setFailed] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(validationMessages.Email)
        .required(validationMessages.Required),
      password: Yup.string().required(validationMessages.Required),
    }),
    onSubmit: async (values) => {
      const response = await dispatch(
        login({
          password: values.password,
          username: values.email,
        })
      );
      if (response.payload) {
        const { is_first_login } = response.payload as IUser;
        history.push(is_first_login ? routePaths.welcome : routePaths.triage);
      } else {
        setFailed(true);
      }
    },
  });

  const messageClasses = classNames({
    "fail-message": true,
    show: failed,
  });

  return (
    <div className="login-form-content">
      <h2>Login</h2>
      <p>Please enter your email address and password:</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="login-fields-container">
          <div className="login-field-row">
            <label>Email:</label>
            <PrimaryInput
              type="email"
              autoCapitalize="false"
              autoCorrect="false"
              autoComplete="username"
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
              {...formik.getFieldProps("email")}
            />
          </div>
          <div className="login-field-row">
            <label>Password:</label>
            <PrimaryInput
              type="password"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="current-password"
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""
              }
              {...formik.getFieldProps("password")}
            />
          </div>
        </div>
        <div className={messageClasses}>Please check username or password</div>
        <div className="login-btn-container">
          <div className="login-float-btn">
            <Button
              text="Login"
              color="primary"
              icon="chevron-right"
              type="submit"
            />
            <Button
              text="Login with O365"
              color="primary"
              icon="chevron-right"
              type="button"
              onClick={() => {
                const baseUrl = process.env.REACT_APP_BASE_URL ?? "";
                const redirectUrl = process.env.REACT_APP_REDERECT_URI ?? "";
                const url = new URL(`${baseUrl}/connect/authorize`);
                url.searchParams.set("client_id", ClientId.O365);
                url.searchParams.set("response_type", "code id_token");
                url.searchParams.set(
                  "scope",
                  "openid profile offline_access portal_api"
                );
                url.searchParams.set("redirect_uri", `${redirectUrl}/o365`);
                url.searchParams.set("acr_values", "idp:o365");
                url.searchParams.set("nonce", "n-0S6_WzA2Mj");
                window.location.replace(url.toString());
              }}
            />
            <Button
              text="Create a free account"
              color="secondary"
              icon="chevron-right"
              onClick={() => history.push(routePaths.register)}
            />
          </div>
          <a href={routePaths.resetpassword}>Forgot Password?</a>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
