import { IFeature, ISessionFeature } from "api/contracts";
import { MultiPolygon, Polygon } from "ol/geom";
import { WKT } from "ol/format";
import GeoJSON from "ol/format/GeoJSON";
import { getCenter } from "ol/extent";

export const createGeometry = (feature: IFeature) => {
  const { type, coordinates } = feature.geometry;
  let polygonGeom;
  if (type === "MultiPolygon" && coordinates.length > 1) {
    polygonGeom = new MultiPolygon(coordinates as number[] | number[][][][]);
  } else {
    polygonGeom = new Polygon(coordinates);
  }
  const wkt = new WKT();
  return { WKT: wkt.writeGeometry(polygonGeom), geometry: polygonGeom };
};

export const readSessionFeature = (sessionFeature: ISessionFeature) => {
  const wkt = new WKT();
  return wkt.readFeature(sessionFeature.wkt);
};

export const readFeature = (feature: IFeature) => {
  const feat = new GeoJSON().readFeature(feature);
  const geom = feat.getGeometry();
  const extent = geom.getExtent();
  const point = getCenter(extent);
  return { geom, extent, point };
};
