import { useEffect, useState } from "react";
import axios, { ErrorStatus } from "./axios-instance";
import { useHistory } from "react-router-dom";
import routePaths from "../app/route-paths";
import { FC } from "react";
import Loader from "components/loader/loader";
import useWorkloadState from "hooks/useWorkloadState";

const InterceptorWrapper: FC = ({ children }) => {
  const history = useHistory();
  const [load, setLoad] = useState(false);

  /* Used to handle workload/session mark inactive */
  useWorkloadState();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        switch (error.status) {
          case ErrorStatus.Unauthorized:
            history.push(routePaths.login);
            break;
          default: {
            //setOpenDialog(true);
            //if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            //alert(error.message);
          }
        }
        return Promise.reject(error);
      }
    );

    setLoad(true);
  }, [history]);

  if (!load) return <Loader />;

  return <>{children}</>;
};

export default InterceptorWrapper;
