import { FC } from "react";
import service from "./service";
import ServiceContext from "./service-context";

const ServiceProvider: FC = ({ children }) => {
  return (
    <ServiceContext.Provider value={service}>
      {children}
    </ServiceContext.Provider>
  );
};

export default ServiceProvider;
