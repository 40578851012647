import { IDevelopment_NEW } from "api/contracts";
import { DropDownGroup } from "components/form/drop-down/drop-down";

export interface IService {
  getGroupedPlots: (development?: IDevelopment_NEW) => DropDownGroup[];
  validationMessages: IValidationMessages;
}

export interface IValidationMessages {
  Required: string;
  Email: string;
  PasswordMismatch: string;
}

class Service implements IService {
  validationMessages = {
    Required: "Required",
    Email: "Invalid email",
    PasswordMismatch: "Password mismatch",
  };

  getGroupedPlots(development?: IDevelopment_NEW) {
    if (!development) return [];
    const result = development.phases.map((dev) => {
      const sortedPlots = dev.plots.slice().sort((a, b) => {
        const an = parseInt(a.number);
        const bn = parseInt(b.number);
        return an > bn ? 1 : bn > an ? -1 : 0;
        //return (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)
      });

      return {
        name: `Phase ${dev.number}`,
        value: dev.number.toString(),
        items: sortedPlots.map((plot) => {
          return {
            name: `Plot ${plot.number}`,
            value: plot.id.toString(),
            tag: plot,
          };
        }),
      };
    });
    // if (development.unassigned_plots.length > 0) {
    //     const unsigned_plots = {
    //         name: 'Unsigned plots',
    //         value: '-1',
    //         items: development.unassigned_plots.map(plot => {
    //             return {
    //                 name: `Plot ${plot.number}`,
    //                 value: plot.id.toString(),
    //                 tag: plot
    //             }
    //         })
    //     }
    //     result.push(unsigned_plots);
    // }
    return result;
  }
}

export default new Service();
