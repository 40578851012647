import React from "react";
import "./footer.scss";
import brand from "../../../assets/images/iso27001.png";
import brand2 from "../../../assets/images/iso9001.png";

interface Props {
  style?: React.CSSProperties;
}

export const FooterComponent: React.FC<Props> = ({ style }) => {
  return (
    <div className="footer" style={style}>
      <div className="title">Collaboration. Trust. Innovation. Expertise.</div>
      <div className="brands">
        <img src={brand} width={100} alt="" />
        <img src={brand2} width={100} alt="" />
      </div>
      <div className="description">
        &copy; CLS Data Limited 2019 - 2024. CLS Data Limited is registered in
        England and Wales with company number 10446473 at 85 Great Portland
        Street, London, W1W 7LT. CLS Data Limited are an Appointed
        Representative of CLS Property Insight Limited who are authorised and
        regulated by the Financial Conduct Authority (reference number 718255).
        VAT Number 269864342.
      </div>
    </div>
  );
};
