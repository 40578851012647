import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rolSlice from "features/rol/rol-slice";
import verifySlice from "features/verify/verify-slice";
import conveymapSlice from "features/conveymap/conveymap-slice";
import newbuildSlice from "features/new-build/newbuild-slice";
import propCheckrSlice from "features/property-checkr/prop-checker-slice";
import userSlice from "auth/authSlice";
import drawSlice from "components/map/ol-map/interactions/draw/drawSlice";
import mapSlice from "components/map/ol-map/map-slice";
import watchlistSlice from "features/watchlist/watchlist-slice";
import apvsSlice from "features/apvs/apvs-slice";

export const store = configureStore({
  reducer: {
    userSlice,
    rolSlice,
    verifySlice,
    conveymapSlice,
    newbuildSlice,
    propCheckrSlice,
    drawSlice,
    mapSlice,
    watchlistSlice,
    apvsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
