export function classNames(...args: any[]) {
  const classes: any[] = [];

  for (const arg of args) {
    if (!arg) {
      continue;
    }

    const argType = typeof arg;

    if (argType === "string") {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames.apply(null, arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (argType === "object") {
      for (const key in arg) {
        if (arg.hasOwnProperty(key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  const result = classes.join(" ");
  return result || "";
}
