import { Suspense } from "react";
import ErrorBoundary from "components/error-boundary/error.boundary";
import LoadingSpinner from "components/loading-spinner/loading-spinner";
import iconMap, { IconName } from "util/icon-map";
import colorMap, { ColorName } from "util/color-map";

type IconProps = {
  name: IconName;
  size?: string;
  fill?: ColorName | string;
};

const Icon = ({ name, size = "1.5rem", fill = "white" }: IconProps) => {
  const iconColor =
    colorMap[fill as ColorName] || fill || colorMap.PrimaryColor;
  const IconComponent = iconMap[name];

  if (!IconComponent) {
    return <div>Icon not found</div>;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <IconComponent width={size} height={size} fill={iconColor} />
    </Suspense>
  );
};

const AppIcon = (props: IconProps) => {
  return (
    <ErrorBoundary fallback={<div>Error</div>}>
      <Icon {...props} />
    </ErrorBoundary>
  );
};

export default AppIcon;
