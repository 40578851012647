import appConfig from "./app-config";
import conveymapConfig from "features/conveymap/conveymap-config";
import verifyConfig from "features/verify/verify-config";
import rolConfig from "features/rol/rol-config";
import newbuildConfig from "features/new-build/newbuild-config";
import propertyCheckrConfig from "features/property-checkr/prop-checkr-config";
import settingsConfig from "features/settings/settings-config";
import watchListConfig from "features/watchlist/watchlist-config";
import workloadConfig from "features/workload/workload-config";
import apvsConfig from "features/apvs/apvs-config";

const generateRoutesFromConfigs = (config: Config[]): RouteConfig[] => {
  let allRoutes: RouteConfig[] = [];
  config.forEach((conf) => {
    const r = conf.routes.map((c) => {
      return { ...c, authRequire: c.authRequire || conf.authRequire || false };
    });
    allRoutes = [...allRoutes, ...r];
  });
  return allRoutes;
};

const routeConfig: Config[] = [
  appConfig,
  conveymapConfig,
  verifyConfig,
  rolConfig,
  newbuildConfig,
  propertyCheckrConfig,
  settingsConfig,
  watchListConfig,
  workloadConfig,
  apvsConfig,
];

const routes = [...generateRoutesFromConfigs(routeConfig)];

export default routes;
