import "./cls-logo.scss";

function ClsLogo() {
  return (
    <div className="cls-logo-container">
      <div className="cls-logo" />
      <div className="cls-portal">Portal</div>
    </div>
  );
}

export default ClsLogo;
