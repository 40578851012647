import Button from "components/form/button/button";
import ModalDialog, {
  ModalBody,
  ModalFooter,
} from "../modal-dialog/modal-dialog";

import "./timeout-dialog.scss";

interface TimeoutDialogProps {
  onKeepSignIn: () => void;
  onSignOut: () => void;
  open: boolean;
  time: string;
}

function TimeoutDialog(props: TimeoutDialogProps) {
  const { onKeepSignIn, onSignOut, open, time } = props;
  return (
    <ModalDialog className="timeout-dialog" open={open}>
      <ModalBody>
        <div>
          <h3>Your session is about to expire!</h3>
          <br />
          <p>
            You will be logged out in <b>{time}</b>.<br />
            Do you want to stay signed in?
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onKeepSignIn}>
          Yes, Keep me signed in
        </Button>
        <Button onClick={onSignOut}>No, Sign me out</Button>
      </ModalFooter>
    </ModalDialog>
  );
}

export default TimeoutDialog;
