import { lazy } from "react";
import paths from "../../app/route-paths";

const rolConfig: Config = {
  authRequire: true,
  routes: [
    {
      path: paths.rol.processRoute,
      component: lazy(() => import("./map/map")),
    },
    {
      path: paths.rol.tab,
      component: lazy(() => import("./dashboard/rol.app")),
    },
  ],
};

export default rolConfig;
