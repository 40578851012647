import { IRefreshTokenResponse } from "api/contracts";
import api, { apiPaths } from "../api/api";
import axios, { ErrorStatus } from "../api/axios-instance";

export enum UserType {
  CLSUser = "CLSUser",
  O365User = "O365User",
}

export enum LocalStorageKey {
  ACCESS_TOKEN = "jwt_access_token",
  REFRESH_TOKEN = "jwt_refresh_token",
  TOKEN_ID = "jwt_token_id",
  USER_TYPE = "user_type",
}

class JwtService {
  constructor() {
    this.setInterceptors();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.url === apiPaths.user.getUserData) {
          this.setSession(null);
        }
        if (error.status === ErrorStatus.Unauthorized) {
          this.setSession(null);
        }
        return Promise.reject(error);
      }
    );
  };

  isSessionExist = () => {
    return !!axios.defaults.headers.common.Authorization;
  };

  setSession = (value: IRefreshTokenResponse | null) => {
    if (value) {
      this.setAccessToken(value.access_token);
      this.setRefreshToken(value.refresh_token);
      this.setTokenId(value.id_token);
    } else {
      this.setAccessToken();
      this.setRefreshToken();
      this.setTokenId();
      this.setUserType();
    }
  };

  getTokenId = (): string | null => {
    return this.getToken(LocalStorageKey.TOKEN_ID);
  };

  setTokenId = (tokenId?: string) => {
    this.setToken(tokenId, LocalStorageKey.TOKEN_ID);
  };

  getUserType = (): UserType | null => {
    return this.getToken(LocalStorageKey.USER_TYPE) as UserType | null;
  };

  setUserType = (type?: UserType) => {
    this.setToken(type, LocalStorageKey.USER_TYPE);
  };

  getRefreshToken = (): string | null => {
    return this.getToken(LocalStorageKey.REFRESH_TOKEN);
  };

  setRefreshToken = (refresh_token?: string) => {
    this.setToken(refresh_token, LocalStorageKey.REFRESH_TOKEN);
  };

  getAccessToken = (): string | null => {
    return this.getToken(LocalStorageKey.ACCESS_TOKEN);
  };

  setAccessToken = (access_token?: string) => {
    this.setToken(access_token, LocalStorageKey.ACCESS_TOKEN);
    if (access_token) {
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      api.authToken = access_token;
    } else {
      delete axios.defaults.headers.common.Authorization;
      api.authToken = "";
    }
  };

  getToken = (tokenKey: LocalStorageKey): string | null => {
    return window.localStorage.getItem(tokenKey);
  };

  setToken = (token: string | undefined | null, tokenKey: LocalStorageKey) => {
    if (token) window.localStorage.setItem(tokenKey, token);
    else window.localStorage.removeItem(tokenKey);
  };
}

const instance = new JwtService();

export default instance;
