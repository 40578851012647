import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export enum ErrorStatus {
  UnknownError = 0,
  Unauthorized = 401,
  BadRequest = 400,
  BackendError = 500,
}

export interface APIErrorResponse {
  status: ErrorStatus;
  response?: AxiosResponse;
  message: string;
}

export interface APIResponse<T> {
  success: boolean;
  data: T | null;
  error: string;
}

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError): Promise<APIErrorResponse> => {
    let status = ErrorStatus.UnknownError;
    switch (error.response?.status) {
      case 401:
        status = ErrorStatus.Unauthorized;
        break;
      case 400:
        status = ErrorStatus.BadRequest;
        break;
      case 500:
        status = ErrorStatus.BackendError;
        break;
      default:
        status = ErrorStatus.UnknownError;
        break;
    }
    return Promise.reject({
      status,
      response: error.response,
      message: error.message,
    });
  }
);

export default instance;
