import { lazy } from "react";

export type IconName =
  | "add"
  | "avatar"
  | "back-arrow"
  | "back-arrow-default"
  | "back-arrow-primary"
  | "binoculars-default"
  | "binoculars-white"
  | "binoculars"
  | "calendar"
  | "cancel"
  | "card-lookup"
  | "card-map"
  | "check-button"
  | "chevron-down-danger"
  | "chevron-down-darkgreen"
  | "chevron-down-default"
  | "chevron-down-disabled"
  | "chevron-down-lightgreen"
  | "chevron-down-primary"
  | "chevron-left"
  | "chevron-left-danger"
  | "chevron-left-default"
  | "chevron-left-disabled"
  | "chevron-left-primary"
  | "chevron-right"
  | "chevron-right-danger"
  | "chevron-right-default"
  | "chevron-right-disabled"
  | "chevron-right-primary"
  | "cls-logo"
  | "color-wheel"
  | "cross-button"
  | "danger"
  | "details-bathrooms"
  | "details-bedrooms"
  | "details-floor-space"
  | "details-living"
  | "doccheck"
  | "excel"
  | "excel-default"
  | "excel-disabled"
  | "eye"
  | "file-empty"
  | "filter-default"
  | "filter-primary"
  | "generate-button"
  | "house-find"
  | "house-pound"
  | "layers"
  | "linkedin-normal"
  | "linkedin"
  | "magnifier"
  | "map-marker"
  | "measure-ruler"
  | "module-key"
  | "module-map"
  | "module-prop-checker"
  | "module-rol"
  | "module-settings"
  | "module-verify"
  | "order-arrows"
  | "password-reset"
  | "pencil"
  | "plus-default"
  | "plus"
  | "print-button"
  | "propcheck-primary"
  | "save"
  | "streetview"
  | "team"
  | "tick"
  | "tick36"
  | "tick48"
  | "tick54"
  | "tick88"
  | "twitter"
  | "white-close-button";

const iconMap = {
  add: lazy(() =>
    import("../assets/icons/add.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  avatar: lazy(() =>
    import("../assets/icons/avatar.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "back-arrow": lazy(() =>
    import("../assets/icons/back-arrow.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "back-arrow-default": lazy(() =>
    import("../assets/icons/back-arrow-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "back-arrow-primary": lazy(() =>
    import("../assets/icons/back-arrow-primary.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "binoculars-default": lazy(() =>
    import("../assets/icons/binoculars-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "binoculars-white": lazy(() =>
    import("../assets/icons/binoculars-white.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  binoculars: lazy(() =>
    import("../assets/icons/binoculars.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  calendar: lazy(() =>
    import("../assets/icons/calendar.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  cancel: lazy(() =>
    import("../assets/icons/cancel.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "card-lookup": lazy(() =>
    import("../assets/icons/card-lookup.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "card-map": lazy(() =>
    import("../assets/icons/card-map.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "check-button": lazy(() =>
    import("../assets/icons/check-button.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-down-danger": lazy(() =>
    import("../assets/icons/chevron-down-danger.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-down-darkgreen": lazy(() =>
    import("../assets/icons/chevron-down-darkgreen.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-down-default": lazy(() =>
    import("../assets/icons/chevron-down-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-down-disabled": lazy(() =>
    import("../assets/icons/chevron-down-disabled.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-down-lightgreen": lazy(() =>
    import("../assets/icons/chevron-down-lightgreen.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-down-primary": lazy(() =>
    import("../assets/icons/chevron-down-primary.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-left": lazy(() =>
    import("../assets/icons/chevron-left.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-left-danger": lazy(() =>
    import("../assets/icons/chevron-left-danger.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-left-default": lazy(() =>
    import("../assets/icons/chevron-left-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-left-disabled": lazy(() =>
    import("../assets/icons/chevron-left-disabled.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-left-primary": lazy(() =>
    import("../assets/icons/chevron-left-primary.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-right": lazy(() =>
    import("../assets/icons/chevron-right.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-right-danger": lazy(() =>
    import("../assets/icons/chevron-right-danger.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-right-default": lazy(() =>
    import("../assets/icons/chevron-right-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-right-disabled": lazy(() =>
    import("../assets/icons/chevron-right-disabled.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "chevron-right-primary": lazy(() =>
    import("../assets/icons/chevron-right-primary.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "cls-logo": lazy(() =>
    import("../assets/icons/cls-logo.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "color-wheel": lazy(() =>
    import("../assets/icons/color-wheel.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "cross-button": lazy(() =>
    import("../assets/icons/cross-button.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  danger: lazy(() =>
    import("../assets/icons/danger.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "details-bathrooms": lazy(() =>
    import("../assets/icons/details-bathrooms.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "details-bedrooms": lazy(() =>
    import("../assets/icons/details-bedrooms.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "details-floor-space": lazy(() =>
    import("../assets/icons/details-floor-space.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "details-living": lazy(() =>
    import("../assets/icons/details-living.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  doccheck: lazy(() =>
    import("../assets/icons/doccheck.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  excel: lazy(() =>
    import("../assets/icons/excel.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "excel-default": lazy(() =>
    import("../assets/icons/excel-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "excel-disabled": lazy(() =>
    import("../assets/icons/excel-disabled.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  eye: lazy(() =>
    import("../assets/icons/eye.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "file-empty": lazy(() =>
    import("../assets/icons/file-empty.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "filter-default": lazy(() =>
    import("../assets/icons/filter-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "filter-primary": lazy(() =>
    import("../assets/icons/filter-primary.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "generate-button": lazy(() =>
    import("../assets/icons/generate-button.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "house-find": lazy(() =>
    import("../assets/icons/house-find.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "house-pound": lazy(() =>
    import("../assets/icons/house-pound.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  layers: lazy(() =>
    import("../assets/icons/layers.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "linkedin-normal": lazy(() =>
    import("../assets/icons/linkedin-normal.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  linkedin: lazy(() =>
    import("../assets/icons/linkedin.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  magnifier: lazy(() =>
    import("../assets/icons/magnifier.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "map-marker": lazy(() =>
    import("../assets/icons/map-marker.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "measure-ruler": lazy(() =>
    import("../assets/icons/measure-ruler.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "module-key": lazy(() =>
    import("../assets/icons/module-key.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "module-map": lazy(() =>
    import("../assets/icons/module-map.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "module-prop-checker": lazy(() =>
    import("../assets/icons/module-prop-checker.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "module-rol": lazy(() =>
    import("../assets/icons/module-rol.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "module-settings": lazy(() =>
    import("../assets/icons/module-settings.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "module-verify": lazy(() =>
    import("../assets/icons/module-verify.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "order-arrows": lazy(() =>
    import("../assets/icons/order-arrows.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "password-reset": lazy(() =>
    import("../assets/icons/password-reset.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  pencil: lazy(() =>
    import("../assets/icons/pencil.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "plus-default": lazy(() =>
    import("../assets/icons/plus-default.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  plus: lazy(() =>
    import("../assets/icons/plus.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "print-button": lazy(() =>
    import("../assets/icons/print-button.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "propcheck-primary": lazy(() =>
    import("../assets/icons/propcheck-primary.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  save: lazy(() =>
    import("../assets/icons/save.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  streetview: lazy(() =>
    import("../assets/icons/streetview.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  team: lazy(() =>
    import("../assets/icons/team.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  tick: lazy(() =>
    import("../assets/icons/tick.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  tick36: lazy(() =>
    import("../assets/icons/tick36.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  tick48: lazy(() =>
    import("../assets/icons/tick48.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  tick54: lazy(() =>
    import("../assets/icons/tick54.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  tick88: lazy(() =>
    import("../assets/icons/tick88.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  twitter: lazy(() =>
    import("../assets/icons/twitter.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  "white-close-button": lazy(() =>
    import("../assets/icons/white-close-button.svg").then((module) => ({
      default: module.ReactComponent,
    }))
  ),
};

export default iconMap;
