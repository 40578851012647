import "./App.scss";
import Routing from "./app/Routing";
import routes from "./app/route-config";
import ApiProvider from "./api/api.provider";
import ServiceProvider from "service/service-provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ApiProvider>
      <ServiceProvider>
        <Routing routeConfig={routes} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          draggable={false}
          closeOnClick
        />
      </ServiceProvider>
    </ApiProvider>
  );
}

export default App;
