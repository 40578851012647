import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import { IAddress, ICase, ICasePayload } from "api/contracts";

export const getCase = createAsyncThunk(
  "verify/getCase",
  async (caseId: string, { dispatch, getState }) => {
    return await api.verify.getCase({ caseId });
  }
);

export const addCase = createAsyncThunk(
  "verify/addCase",
  async (params = undefined, { dispatch, getState }) => {
    const { verifySlice } = getState() as { verifySlice: ICase };
    return await api.verify.addCase(verifySlice);
  }
);

const initialState: ICase = {
  Reference: "",
  Payload: {
    TitleNumber: "",
    PurchasePrice: "",
    DepositAmount: "",
  },
};

const verifySlice = createSlice({
  name: "verify",
  initialState: null as ICase | null,
  reducers: {
    setAddress: (state, action: PayloadAction<IAddress>) => {
      if (state) state.Address = action.payload;
    },
    setTitleNumber: (state, action: PayloadAction<string>) => {
      if (state) state.Payload.TitleNumber = action.payload;
    },
    setDepositAmount: (state, action: PayloadAction<string>) => {
      if (state) state.Payload.DepositAmount = action.payload;
    },
    setPurchasePrice: (state, action: PayloadAction<string>) => {
      if (state) state.Payload.PurchasePrice = action.payload;
    },
    setPayload: (state, action: PayloadAction<ICasePayload>) => {
      if (state) state.Payload = action.payload;
    },
    setReference: (state, action: PayloadAction<string>) => {
      if (state) state.Reference = action.payload;
    },
    resetCase: () => {
      return null;
    },
    newCase: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCase.fulfilled, (state, action) => {
      return action.payload as ICase;
    });
    builder.addCase(addCase.fulfilled, (state, action) => {
      return action.payload as ICase;
    });
  },
});

export const {
  setAddress,
  setPayload,
  setReference,
  setTitleNumber,
  resetCase,
  newCase,
  setPurchasePrice,
  setDepositAmount,
} = verifySlice.actions;
export default verifySlice.reducer;
